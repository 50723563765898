import React from 'react';
import tw, { css } from 'twin.macro';

interface Props {
  checked: boolean;
  name: string;
  value: string;
  error?: string;
  label: string;
  className?: string;
  classNameLabel?: string;
  classNameInput?: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const RadioField = ({
  checked,
  name,
  value,
  label,
  className,
  classNameLabel,
  classNameInput,
  onChange,
  disabled = false,
}: Props) => {
  return (
    <div className={className || ''}>
      <input
        type='radio'
        id={label}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className={classNameInput || ''}
        tw='focus:ring-0 focus:ring-offset-0'
        disabled={disabled}
      />
      <label
        htmlFor={label}
        tw='ml-2.5 text-sm font-light'
        className={classNameLabel || 'text-black-100'}
      >
        {label}
      </label>
      <br></br>
    </div>
  );
};

export default RadioField;
