import React from 'react';

interface Props {
  className?: string;
}

function RemoveIcon({ className }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='9'
      height='9'
      fill='none'
      viewBox='0 0 9 9'
      className={className || ''}
    >
      <path
        fill='#fff'
        d='M5.627 4L8.867.76A.444.444 0 008.24.133L5 3.373 1.76.13a.444.444 0 00-.627.627L4.373 4 1.13 7.24a.444.444 0 10.627.627L5 4.627l3.24 3.24a.444.444 0 00.627-.627L5.627 4z'
      ></path>
    </svg>
  );
}

export default RemoveIcon;
