interface Props {
  className?: string;
}

function ProfileIcon({ className }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='34'
      height='35'
      viewBox='0 0 34 35'
      className={className || ''}
    >
      <g fill='none' fillRule='evenodd' transform='translate(0 1)'>
        <g transform='translate(.525 .351)'>
          <path
            stroke='#FFF'
            strokeLinecap='round'
            strokeWidth='0.735'
            d='M13.092 4.775A4.775 4.775 0 118.312 0a4.756 4.756 0 014.78 4.775h0zm3.12 16.449v-2.536a8.085 8.085 0 00-8.077-8.076A8.19 8.19 0 000 18.747v2.534a16.057 16.057 0 008.076 1.826 16.923 16.923 0 008.137-1.883h0z'
            transform='translate(8.088 4.622)'
          ></path>
          <circle cx='16.177' cy='16.177' r='16.177'></circle>
          <circle cx='16.177' cy='16.177' r='15.81' stroke='#FFF' strokeWidth='0.735'></circle>
        </g>
        <path
          stroke='#390'
          strokeLinecap='round'
          strokeWidth='2.205'
          d='M10.828 20.574L16.501 26.332 28.164 14.425'
        ></path>
      </g>
    </svg>
  );
}

export default ProfileIcon;
