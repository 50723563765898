import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Windmill } from '@windmill/react-ui';
import Routes from './Routes';
import LoadingSpinner from './components/common/LoadingSpinner';
import { ToastContainer } from 'react-toastify';

import tw from 'twin.macro';
import 'react-toastify/dist/ReactToastify.css';
import 'moment/locale/bg';
import 'moment/locale/en-gb';
import 'moment/locale/ro';
import 'moment/locale/el'; // without this line it didn't work
import moment from 'moment';
import './i18n';

const CLDR_MONTHS_BG = [
  'Януари',
  'Февруари',
  'Март',
  'Април',
  'Май',
  'Юни',
  'Юли',
  'Август',
  'Септември',
  'Октомври',
  'Ноември',
  'Декември',
];

const getMomentLanguage = () => {
  const lang = localStorage.getItem('lang');
  if (lang) {
    if (lang === 'gr') return 'el';
    else if (lang === 'en') return 'en-gb';
    else return lang;
  } else return 'bg';
};

moment.updateLocale(
  getMomentLanguage(),
  getMomentLanguage() === 'bg' ? { months: CLDR_MONTHS_BG } : {}
);
moment.locale(getMomentLanguage());

function App() {
  return (
    <BrowserRouter>
      <Windmill>
        <Suspense fallback={<LoadingSpinner />}>
          <ToastContainer
            position='bottom-right'
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeButton={false}
            theme='light'
          />
          <Routes />
        </Suspense>
      </Windmill>
    </BrowserRouter>
  );
}

export default App;

// #07bc0c
