interface Props {
  className?: string;
}

function BurgerIcon({ className }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='27'
      height='20'
      viewBox='0 0 27 20'
      className={className || ''}
    >
      <path
        fill='#FFF'
        d='M22.938 16c1.065 0 2.062.672 2.062 2.016C25 19.344 23.996 20 22.916 20H5.066C3.997 20 3 19.33 3 17.987 3 16.664 4.002 16 5.082 16h17.855zm2.04-8C26.027 8 27 8.672 27 10.015 27 11.336 26.019 12 24.959 12H2.022C.972 12 0 11.328 0 9.987 0 8.664.977 8 2.041 8h22.937zm-2.04-8C24.003 0 25 .673 25 2.016 25 3.343 23.996 4 22.916 4H5.066C3.997 4 3 3.332 3 1.988 3 .665 4.002 0 5.082 0h17.855z'
      ></path>
    </svg>
  );
}

export default BurgerIcon;
