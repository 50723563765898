import { useEffect } from 'react';

const useOnClickOutside = (ref: any, handler: Function) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };
    document.addEventListener('click', listener);
    document.addEventListener('touch', listener);
    return () => {
      document.removeEventListener('click', listener);
      document.removeEventListener('touch', listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
