import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import useAuthHook from '../hooks/use-auth-hook';

import Wrapper from '../components/layout/Wrapper';
import ClaimsList from '../components/features/ClaimsList';
import LoadingSpinner from '../components/common/LoadingSpinner';

import { getClaimsExternal } from '../services/actions';
import useQuery from '../hooks/use-query-hook';

import { ClaimItemType, Status } from '../types/Data';
import { claimReasonParser } from '../utils/dataFormatter';

import 'twin.macro';
import {
  getLastHistoryElement_time,
  isClaimAggreedWithDecisionThroughEcoints,
  isClaimReceivedSecondResponseWithoutCompensation,
  isClaimWaitingForEcontResponse,
} from '../utils/helpersFromClaimHistory';
import FirstClaimBox from '../components/common/FirstClaimBox';
import useAuthRedirect from '../hooks/use-auth-redirect-hook';
import routePaths from '../route-paths';
import { useTranslation } from 'react-i18next';

const ClaimsListPage = () => {
  const { t } = useTranslation();
  const { onRedirect } = useAuthRedirect();
  const { page, ...queryParameters } = useQuery();
  const [activePage, setActivePage] = useState('1');
  const [activeClaims, setActiveClaims] = useState(0);
  const [finishClaims, setFinishClaims] = useState(0);

  const skipAuth = !!(
    queryParameters.idUser &&
    queryParameters.hashKey &&
    queryParameters.hashKey2
  );

  const { authLoading, isAuthenticated, isMount } = useAuthHook(skipAuth);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [claimsList, setClaimsList] = useState<ClaimItemType[]>([]);

  const fetchData = useCallback(
    (status: string, getPage: string) => {
      if (isAuthenticated && isMount) {
        setLoading(true);
        const { skipNumbers, ...restParams } = queryParameters;
        getClaimsExternal(restParams, status, getPage)
          .then((result: any) => {
            const updatedData = result.data?.result?.reclamations?.map((item: any, index: any) => {
              return {
                ...item,
                deadlineDays:
                  item.status && item.status === 'processed'
                    ? getLastHistoryElement_time(item.history)
                    : moment(item.processDeadline).format('DD/MM/YYYY H:mm'),
                date: moment(item.process_term).format('DD/MM/YYYY'),
                number: item.number,
                shipmentNumbers: item.shipmentNumbers.join(', '),
                label: claimReasonParser([item.claimCode], t),
                compensationStatus: isClaimAggreedWithDecisionThroughEcoints(
                  item.compensationStatus,
                  item.isClientAgreeWithDecision,
                  item.paymentOptions
                ).valid
                  ? isClaimAggreedWithDecisionThroughEcoints(
                      item.compensationStatus,
                      item.isClientAgreeWithDecision,
                      item.paymentOptions
                    ).status
                  : isClaimReceivedSecondResponseWithoutCompensation(
                      item.history,
                      item.compensationAmount,
                      item.offerCode
                    )
                  ? Status.DoneUnpayed
                  : isClaimWaitingForEcontResponse(item.history)
                  ? Status.Pending
                  : !item.compensationStatus && item.offerCode === '0103'
                  ? Status.Done
                  : !item.compensationStatus && item.offerCode !== '0103'
                  ? Status.DoneUnpayed
                  : !item.decisionFileUrl && item.compensationStatus === Status.Received
                  ? Status.Pending
                  : item.compensationStatus,
                receivedButWithoutDecisionFileUrl:
                  !item.decisionFileUrl && item.compensationStatus === Status.Received
                    ? true
                    : false,
              };
            });

            setActiveClaims(result.data?.result?.totalUnprocessedReclamations || 0);
            setFinishClaims(result.data?.result?.totalProcessedReclamations || 0);
            setClaimsList(updatedData || []);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            setError(err);
          });
      }
    },
    [isAuthenticated, isMount]
  );

  useEffect(() => {
    fetchData('unprocessed', activePage.toString());
  }, [fetchData]);

  useEffect(() => {
    setActivePage(page || '1');
  }, [page]);

  const renderContent = () => {
    if (authLoading) {
      return <LoadingSpinner />;
    }
    if (error) {
      <p className='mt-4 text-red-500'>{t('a_technical_inspection_of_the_site_is_being_carried_out')}</p>;
    }
    return (
      <>
        {claimsList &&
        claimsList.length === 0 &&
        activeClaims === 0 &&
        finishClaims === 0 &&
        !loading ? (
          <FirstClaimBox
            onClick={() => {
              onRedirect(
                routePaths.root,
                { ...queryParameters, skipNumbers: 'true' },
                { ...queryParameters, skipNumbers: 'true' }
              );
            }}
          />
        ) : (
          <ClaimsList
            listData={claimsList}
            activePage={activePage ? +activePage : 1}
            active={activeClaims}
            finish={finishClaims}
            loading={loading}
            setLoading={setLoading}
            refetchData={fetchData}
            setActivePage={setActivePage}
          />
        )}
      </>
    );
  };

  return <Wrapper>{renderContent()}</Wrapper>;
};

export default ClaimsListPage;
