import React, { useEffect } from 'react';
import tw from 'twin.macro';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';

interface Tel {
  tel: any;
  setTel: any;
  errorTel: String;
  setErrorTel: Function;
}

const EcointsPayment = ({ tel, setTel, errorTel, setErrorTel }: Tel) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (tel && !isValidPhoneNumber(tel.toString())) {
      setErrorTel(t('invalid_number'));
    } else {
      setErrorTel('');
    }
  }, [tel, setErrorTel]);

  return (
    <div tw='ml-10'>
      <p tw='text-[11px] my-2' style={{ fontFamily: 'Open Sans, Regular', color: '#424242' }}>
        {t('phone_number_where_you_will_receive_a_payment_notification')}
      </p>

      <PhoneInput
        className='phone-input-ecoints'
        defaultCountry='BG'
        countries={['BG']}
        placeholder={'phone_number'}
        value={tel}
        onChange={setTel}
      />
      <p tw='text-red-500 text-[13px]'>{errorTel}</p>
    </div>
  );
};

export default EcointsPayment;
