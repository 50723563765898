const routePaths = {
  root: '/',
  myClaims: '/myClaims/',
  viewClaim: '/viewClaim/:id',
  viewClaimByCommonId: '/viewClaimByCommonId/:id',
  payOnline: '/pay-online/:id',
  downloadFilePage: '/download_file/:url/:access',
  errorInvalidHash: '/error-invalid-hash',
  errorExpiredHash: '/error-expired-hash',
};

export default routePaths;
