import React, { useCallback, useEffect, useState } from 'react';

import Button from '../Button/Button';
import ButtonWrapper from '../Button/ButtonWrapper';
import CreateClaimWrapper from '../layout/CreateClaimWrapper';

import CheckboxField from '../form/CheckboxField';
import TextareaField from '../form/TextareaField';
import FileUploaderField from '../form/FileUploadeField';
import { FieldsNames, ReturnFormData } from '../../types/Form';

import 'twin.macro';
import GrayBackgroundWrapper from '../layout/GrayBackgroundWrapper';
import LeftArrow from '../icons/LeftArrow';
import { containsOnlyNumbers } from '../../utils/stringFormatters';
import useQuery from '../../hooks/use-query-hook';
import useAuthRedirect from '../../hooks/use-auth-redirect-hook';
import routePaths from '../../route-paths';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const MAX_FILES_SIZE = 30000000;

interface Props {
  initialData: ReturnFormData | null;
  handleSaveData: (data: ReturnFormData) => void;
  awbNumbers?: string;
  moneyTransferNumbers?: string;
  errorMessagesOnSubmit?: Array<string>;
  clearErrorsOnSubmit: Function;
  goBack: Function;
  setFail?: Function;
  touched: boolean;
  shouldRestartData?: boolean;
}

const initialState: ReturnFormData = {
  [FieldsNames.Checkbox1]: false,
  [FieldsNames.Checkbox2]: false,
  [FieldsNames.Checkbox3]: false,
  [FieldsNames.Checkbox4]: false,
  [FieldsNames.Claimer]: 'sender',
  [FieldsNames.RegardingShipment]: '',
  [FieldsNames.Other]: '',
  [FieldsNames.Files]: [],
};

interface ErrorsValidation {
  [key: string]: string;
}

interface Validation {
  errors: ErrorsValidation;
  isValid: boolean;
}

const validateFields = (
  values: ReturnFormData,
  t: Function,
  errorMessagesOnSubmit?: Array<string>,
  setFail?: Function
): Validation => {
  const errors: ErrorsValidation = {};

  if (errorMessagesOnSubmit && errorMessagesOnSubmit.length > 0 && errorMessagesOnSubmit[0]) {
    errorMessagesOnSubmit.forEach((err) => {
      let error = err;
      if (error.includes('вече има заведена')) error = 'вече има заведена';
      if (error.includes('6 месеца')) error = '6 месеца';

      switch (error) {
        case 'Не са открити товарителници или преводи с посочените номера.': {
          errors[FieldsNames.RegardingShipment] =
            'Не са открити товарителници или преводи с посочените номера.';
          break;
        }
        case 'вече има заведена': {
          errors[FieldsNames.RegardingShipment] =
            'Вече има заведена рекламация по услугата, моля проверете статуса й.';
          break;
        }
        case '6 месеца': {
          errors[FieldsNames.RegardingShipment] = errorMessagesOnSubmit as any;
          break;
        }
        default:
          if (setFail) {
            setFail(t('due_to_technical_error'));
          }
          break;
      }
    });
  }

  if (
    !values[FieldsNames.Checkbox1] &&
    !values[FieldsNames.Checkbox2] &&
    !values[FieldsNames.Checkbox3] &&
    values[FieldsNames.Checkbox4]
  ) {
    if (
      !values[FieldsNames.RegardingShipment] &&
      !containsOnlyNumbers(values[FieldsNames.RegardingShipment])
    ) {
      errors[FieldsNames.RegardingShipment] = t('please_enter_a_correct_shipment');
    }
  } else if (!containsOnlyNumbers(values[FieldsNames.RegardingShipment]))
    errors[FieldsNames.RegardingShipment] = t('please_enter_a_correct_shipment');

  if (values[FieldsNames.RegardingShipment]) {
    const createArrayFromValues = values[FieldsNames.RegardingShipment]?.split(',');

    const toFindDuplicates = (arry: any) =>
      arry.filter((item: any, index: number) => arry.indexOf(item) !== index);
    const duplicateElements = toFindDuplicates(createArrayFromValues);

    if (duplicateElements && duplicateElements.length) {
      errors[FieldsNames.RegardingShipment] = t('you_have_entered_a_duplicate_number');
    }
  }

  if (
    !values[FieldsNames.Checkbox1] &&
    !values[FieldsNames.Checkbox2] &&
    !values[FieldsNames.Checkbox3] &&
    !values[FieldsNames.Checkbox4]
  ) {
    errors[FieldsNames.Checkbox1] = t('please_select_a_complaint_reason');
  }

  const files = values[FieldsNames.Files];
  if (files.length > 5) {
    errors[FieldsNames.Files] = t('you_are_allowed_to_upload_up_to_5_files');
  }

  if (files) {
    let totalSize = 0;
    files.forEach((item) => {
      totalSize += item.file.size;
    });

    if (totalSize > MAX_FILES_SIZE) {
      errors[FieldsNames.Files] = t('maximum_size_is_30_mb');
    }
  }

  if (values[FieldsNames.Checkbox2]) {
    if (!values[FieldsNames.Other]) {
      errors[FieldsNames.Other] = t('please_describe_the_reason_for_the_complaint');
    }

    if (files.length === 0) {
      errors[FieldsNames.Files] = t('please_upload_at_least_1_file');
    }
  }

  if (values[FieldsNames.Checkbox3]) {
    if (files.length === 0) {
      errors[FieldsNames.Files] = t('please_upload_at_least_1_file');
    }
  }

  if (values[FieldsNames.Checkbox4]) {
    if (!values[FieldsNames.Other]) {
      errors[FieldsNames.Other] = t('please_describe_the_reason_for_the_complaint');
    }
  }

  if (values && values[FieldsNames.Other] && values[FieldsNames.Other].length < 10) {
    errors[FieldsNames.Other] = t('please_enter_text_that_contains_at_least_10_characters');
  }

  console.log(errors);

  const isValid = Object.keys(errors).length > 0;

  return {
    errors,
    isValid,
  };
};

const ReturnForm = ({
  initialData,
  handleSaveData,
  errorMessagesOnSubmit,
  clearErrorsOnSubmit,
  goBack,
  setFail,
  touched,
  shouldRestartData,
}: Props) => {
  const { t } = useTranslation();

  const [values, setValue] = useState<ReturnFormData>(initialData || initialState);
  const [isTouched, setTouched] = useState<boolean>(touched);

  const { errors, isValid } = validateFields(values, t, errorMessagesOnSubmit, setFail);

  const queryParameters = useQuery();
  const { onRedirect } = useAuthRedirect();

  useEffect(() => {
    if (queryParameters) {
      const { awbNumbers, sourceApp, shipmentNumber, preparedShipment, compensationSide } =
        queryParameters;

      if (compensationSide && (compensationSide === 'sender' || compensationSide === 'receiver')) {
        setValue({
          ...values,
          claimer: compensationSide,
        });
      }
      if (awbNumbers) {
        setValue({
          ...values,
          regardingShipment: awbNumbers,
        });
      }

      if (
        (sourceApp === 'mobileAppEcont' || sourceApp === 'webAppEcont') &&
        shipmentNumber &&
        preparedShipment
      ) {
        setValue({
          ...values,
          regardingShipment: shipmentNumber,
          [FieldsNames.Checkbox4]: true,
          claimer: compensationSide || 'sender',
        });
      } else if (
        (sourceApp === 'mobileAppEcont' || sourceApp === 'webAppEcont') &&
        shipmentNumber
      ) {
        setValue({
          ...values,
          regardingShipment: shipmentNumber,
          [FieldsNames.Checkbox1]: true,
          claimer: compensationSide || 'sender',
        });
      }
    }
  }, []);

  useEffect(() => {
    if (shouldRestartData) {
      setValue(initialData || initialState);
      setTouched(false);
    }
  }, [shouldRestartData]);

  const onSubmit = (event: any) => {
    event.preventDefault();
    if (!isTouched) {
      setTouched(true);
      if (isValid) return;
    }

    if (isValid) return;

    handleSaveData(values);
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    const { type, name, checked, value } = e.target;

    if (name === FieldsNames.RegardingShipment) {
      clearErrorsOnSubmit();
    }

    if (
      name === FieldsNames.Checkbox4 ||
      name === FieldsNames.Checkbox1 ||
      name === FieldsNames.Checkbox2 ||
      name === FieldsNames.Checkbox3
    ) {
      const isAnotherCheckboxSelected = values.checkbox1 || values.checkbox2 || values.checkbox3;

      if (!isAnotherCheckboxSelected) {
        if (checked) {
          setValue({
            ...values,
            [name]: checked,
            claimer: queryParameters?.compensationSide || 'sender',
          });
        } else {
          setValue({
            ...values,
            [name]: checked,
            claimer: queryParameters?.compensationSide || '',
          });
        }

        return;
      }
    }

    const newValue = type === 'checkbox' ? checked : value;
    setValue({
      ...values,
      [name]: newValue,
    });
  };

  const handleFilesChange = useCallback(
    (files: any) => {
      setValue((prevState) => ({
        ...prevState,
        [FieldsNames.Files]: files,
      }));
    },
    [setValue]
  );

  const handleCancel = () => {
    onRedirect(routePaths.myClaims, queryParameters, queryParameters);
  };

  const renderButtons = () => {
    return (
      <div tw='flex mt-5 justify-between mobile:(flex-col-reverse) tablet:(relative flex-col-reverse items-center) laptop:(flex-row)'>
        <ButtonWrapper>
          <button
            type='button'
            tw='text-blue-500 font-light text-sm font-body mobile:(mt-5) tablet:(mt-5) laptop:(mt-0)'
            onClick={handleCancel}
          >
            {t('cancel')}
          </button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            type='submit'
            variant='cool'
            disabled={false}
            width={isMobile || isTablet ? '100%' : ''}
          >
            {t('send_claim')}
          </Button>
        </ButtonWrapper>
      </div>
    );
  };

  return (
    <form tw='font-body' onSubmit={onSubmit}>
      <GrayBackgroundWrapper />
      <CreateClaimWrapper buttonComponent={<></>}>
        <>
          <div
            className={`flex cursor-pointer ${
              localStorage.getItem('hideHeader') ? 'mt-0' : 'mobile:mt-16 laptop:mt-20'
            }`}
            onClick={() => goBack()}
          >
            <span tw='flex items-center justify-start font-light text-xl font-body text-black-100'>
              <LeftArrow width='24' height='24' fill='rgb(66 66 66)' viewbox='0 0 19 16' />
              {t('file_a_claim')}
            </span>
          </div>
          <div tw='mt-5 p-7 bg-white rounded-lg'>
            <h3 tw='text-black-light text-base font-semibold font-light'>{t('choose_a_reason')}</h3>

            <div tw='mt-4'>
              <CheckboxField
                label={t('missing_or_incomplete_shipment')}
                name={FieldsNames.Checkbox3}
                checked={values[FieldsNames.Checkbox3]}
                onChange={handleInputChange}
              />
            </div>

            <div tw='mt-4'>
              <CheckboxField
                label={t('damaged_shipment')}
                name={FieldsNames.Checkbox2}
                checked={values[FieldsNames.Checkbox2]}
                onChange={handleInputChange}
              />
            </div>

            <div tw='mt-4'>
              <CheckboxField
                label={t('delayed_delivery')}
                name={FieldsNames.Checkbox1}
                checked={values[FieldsNames.Checkbox1]}
                onChange={handleInputChange}
              />
            </div>

            <div tw='mt-4'>
              <CheckboxField
                label={t('other')}
                name={FieldsNames.Checkbox4}
                checked={values[FieldsNames.Checkbox4]}
                onChange={handleInputChange}
                error={isTouched ? errors[FieldsNames.Checkbox1] : ''}
              />
            </div>
            <h3 tw='text-black-light font-light mt-5'>{t('required_information')}</h3>
            {(values[FieldsNames.Checkbox1] ||
              values[FieldsNames.Checkbox2] ||
              values[FieldsNames.Checkbox3] ||
              values[FieldsNames.Checkbox4]) && (
              <div tw='mt-4'>
                <TextareaField
                  name={FieldsNames.RegardingShipment}
                  placeholder={t('enter_shipment_request_or_other')}
                  value={values[FieldsNames.RegardingShipment]}
                  error={isTouched ? errors[FieldsNames.RegardingShipment] : ''}
                  onChange={handleInputChange}
                />
              </div>
            )}
            <div tw='mt-4'>
              <TextareaField
                large={true}
                name={FieldsNames.Other}
                placeholder={t('describe_the_situation_in_detail')}
                value={values[FieldsNames.Other]}
                error={isTouched ? errors[FieldsNames.Other] : ''}
                onChange={handleInputChange}
              />
            </div>

            <div tw='mt-4'>
              <FileUploaderField
                value={values[FieldsNames.Files] || []}
                name={FieldsNames.Files}
                onChange={handleFilesChange}
                placeholder={t('up_to_5_photos_short_video_or_document')}
                error={errors[FieldsNames.Files]}
              />
            </div>
            <div>{renderButtons()}</div>
          </div>
        </>
      </CreateClaimWrapper>
    </form>
  );
};

export default ReturnForm;
