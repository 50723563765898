import { useEffect, useLayoutEffect, useState } from 'react';
import LoadingSpinner from '../../common/LoadingSpinner';
import GrayBackgroundWrapper from '../GrayBackgroundWrapper';
import TabsHeader from './TabHeader';
import { TabList } from './types';
import useQuery from '../../../hooks/use-query-hook';
import useSwipeHook from '../../../hooks/use-swipe-hook';

interface Props {
  tabList: TabList[];
  activeTab: number;
  showEcointsIcon?: boolean;
  refetchData?: Function;
  setActivePage?: Function;
  loading?: boolean;
  totalResults?: number;
  setLoading?: Function;
  activePage?: any;
}

const Tabs = ({
  activeTab = 0,
  tabList,
  setActivePage,
  activePage,
  refetchData,
  loading,
  totalResults,
}: Props) => {
  const [currentTab, setCurrentTab] = useState<number>(activeTab);
  const [lastTab, setLastTab] = useState<number>(activeTab);
  const [innerLoading, setInnerLoading] = useState<boolean>(false);
  const tabsHeading = tabList.map((item: any) => ({
    label: item.label,
    index: item.index,
    showEcointsIcon: item.showEcointsIcon,
    disabled: item.disabled || false,
  }));

  const renderContent = () => {
    return tabList[currentTab].content;
  };

  const queryParameters = useQuery();

  useLayoutEffect(() => {
    setInnerLoading(true);
    const changeTab = setTimeout(() => {
      if (localStorage.getItem('currentTab')) {
        setCurrentTab(Number(localStorage.getItem('currentTab')) || 0);
      }
    }, 500);

    return () => clearTimeout(changeTab);
  }, []);

  useEffect(() => {
    let innerLoadingTimeout: any;
    const changeTab = setTimeout(() => {
      if (!refetchData || !setActivePage) return;

      switch (currentTab) {
        case 0:
          if (lastTab === currentTab) {
            refetchData('unprocessed', queryParameters.page || '1');
            setActivePage(queryParameters.page || '1');
          } else {
            refetchData('unprocessed', '1');
            setActivePage('1');
          }
          innerLoadingTimeout = setTimeout(() => {
            setInnerLoading(false);
          }, 2500);
          break;
        case 1:
          if (lastTab === currentTab) {
            refetchData('processed', queryParameters.page || '1');
            setActivePage(queryParameters.page || '1');
          } else {
            refetchData('processed', '1');
            setActivePage('1');
          }
          innerLoadingTimeout = setTimeout(() => {
            setInnerLoading(false);
          }, 2500);
          break;
        default:
          break;
      }
    }, 500);
    return () => {
      clearTimeout(changeTab);
      clearTimeout(innerLoadingTimeout);
    };
  }, [currentTab, refetchData, setActivePage]);

  const onTabChange = (newIndex: number) => {
    setLastTab(currentTab);
    setCurrentTab(newIndex);
    localStorage.setItem('currentTab', newIndex.toString());
  };

  const swipeHandlers = useSwipeHook({
    onSwipedUp: () => console.log('up'),
    onSwipedDown: () => {
      if (refetchData) {
        refetchData(currentTab === 0 ? 'unprocessed' : 'processed', activePage?.toString());
      }
      return;
    },
  });

  return (
    <div className={`font-body`} {...swipeHandlers}>
      {!innerLoading && (
        <TabsHeader
          currentTab={currentTab}
          onTabChange={onTabChange}
          tabs={tabsHeading}
          totalResults={totalResults}
        />
      )}

      {!loading && !innerLoading ? (
        <div className={`${localStorage.getItem('hideHeader') ? 'mt-24' : 'mt-40'}`}>
          {renderContent()}
        </div>
      ) : (
        <>
          <GrayBackgroundWrapper top={'0.1rem'} />
          <LoadingSpinner />
        </>
      )}
    </div>
  );
};

export default Tabs;
