import tw, { css } from 'twin.macro';
import Modal from 'react-modal';
import { MouseEventHandler, useState } from 'react';
import Button from '../Button/Button';
import PhoneIcon from '../icons/PhoneIcon';
import XIcon from '../icons/XIcon';
import { useTranslation } from 'react-i18next';

const modalStyle = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.486)',
    zIndex: 100024234232342342,
  },
  content: {
    border: 'none',
    borderRadius: '8px',
    top: '50%',
    left: '50%',
    width: 'fit-content',
    height: 'fit-content',
    transform: 'translate(-50%, -50%)',
  },
};

interface Props {
  show: boolean;
  text: string;
  subText: string;
  subText2?: string;
  onClick: MouseEventHandler<Element>;
  onClose?: Function;
  phoneText?: string;
  secondaryButton?: boolean;
  primaryButtonText?: string;
  type?: 'success' | 'error';
}

const AlertPopup = ({
  show,
  text,
  subText,
  subText2,
  onClick,
  onClose,
  phoneText,
  secondaryButton,
  primaryButtonText,
  type,
}: Props) => {
  const { t } = useTranslation();
  const [isOpenMainModal, setIsOpenMainModal] = useState(show);

  const closeModal = () => {
    if (onClose) onClose();
    setIsOpenMainModal(false);
  };

  const toggleMainModal = () => {
    setIsOpenMainModal(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpenMainModal}
        onRequestClose={closeModal}
        contentLabel='My dialog'
        style={modalStyle}
      >
        <div className='p-2'>
          <XIcon
            tw='absolute right-6 top-6 cursor-pointer'
            fill='gray'
            width='26'
            height='26'
            onClick={closeModal}
          />
          <div className='text-center'>
            <h1
              className={`${
                type === 'success' ? 'text-green-100' : 'text-red-600'
              } font-light text-2xl px-6`}
            >
              {text}
            </h1>
            <p className='text-gray-700 font-light pt-2'>{subText}</p>
            {subText2 && <p className='text-gray-700 font-light'>{subText2}</p>}
          </div>
          <div tw='mobile:(flex flex-col items-center gap-4) tablet:(flex flex-row justify-between items-end mt-5)'>
            <div>
              <span className='text-sm text-gray-800 font-light whitespace-nowrap'>
                {phoneText || t('contact_an_expert')}
              </span>
              <span
                className='flex items-center text-2.5sm font-light text-blue-800 whitespace-nowrap cursor-pointer'
                onClick={() => window.open(`tel:070017300`)}
              >
                <PhoneIcon width='18' height='18' fill='rgba(21, 101, 192)' />{' '}
                <span tw='ml-2'>0 7001 7300</span>
              </span>
            </div>
            {type !== 'success' && (
              <Button
                type='button'
                disabled={false}
                variant={'cool'}
                onClick={(e) => {
                  onClick(e);
                  toggleMainModal();
                }}
              >
                {primaryButtonText || t('try_again')}
              </Button>
            )}
            {secondaryButton && (
              <Button
                type='button'
                disabled={false}
                variant={'cool-secondary'}
                onClick={() => {
                  window.open(`tel:070017300`);
                }}
              >
                {t('connect_me')}
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AlertPopup;
