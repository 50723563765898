export const removeLastCharacterIfPreviousIsNumber = (str: string) => {
  const parsedString = str.slice(str.length - 2, str.length - 1);
  if (parsedString === '0') {
    return str.slice(0, -1);
  } else if (Number(str.slice(str.length - 2, str.length - 1))) {
    return str.slice(0, -1);
  }
  return str;
};

export const containsOnlyNumbers = (str: string | undefined) => {
  if (!str) return false;
  return /^(\d+,)*\d+$/.test(str.replace(/\s+/g, ','));
};
