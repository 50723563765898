import * as API from './api';
import { setAuthKeys, getAccessToken } from './localStorage';
import { checkboxValues, ReturnFormData, FieldsNames } from '../types/Form';
import { BASE_URL } from '../constants/index';
import { Feedback } from '../types/Feedback';

function getRandomInt(min: number, max: number) {
  return `${Math.floor(Math.random() * (max - min) + min)}`;
}

export const SHIPMENT_NUMBER = getRandomInt(3000000000, 4000000000);

export const signIn = (accountCode: string) => {
  return API.signIn(accountCode)
    .then(({ data }) => {
      if (data) {
        const { accessToken, refreshToken } = data.tokens;
        setAuthKeys(accessToken, refreshToken);
      } else {
        setAuthKeys();
      }
    })
    .catch((err) => {
      console.log(err);
      setAuthKeys();
    });
};

export const hasSession = (parameters: any) => {
  const accessToken = getAccessToken();

  return API.hasSession(accessToken, parameters);
};

export const download = (parameters: any, url: string) => {
  const accessToken = getAccessToken();

  return API.download(accessToken, parameters, url);
};

export const createClaim = async (
  data: ReturnFormData,
  parameters: any,
  skipAuth: boolean,
  t: any
) => {
  const accessToken = getAccessToken();

  const claimFieldsNames = [
    FieldsNames.Checkbox1,
    FieldsNames.Checkbox2,
    FieldsNames.Checkbox3,
    FieldsNames.Checkbox4,
  ];

  let claimCodeExternal: string[] = [];
  let claimCodeInternal: string[] = [];

  claimFieldsNames.forEach((fieldName: FieldsNames) => {
    const selectedClaimCode = data[fieldName];
    if (selectedClaimCode) {
      claimCodeExternal.push(checkboxValues(t)[fieldName as string].claimCode);
    }

    if (selectedClaimCode) {
      claimCodeInternal.push(checkboxValues(t)[fieldName as string].claimCode);
    }
  });

  const addComma = parameters.awbNumbers && parameters.moneyTransferNumbers;

  const serviceNumber = `${parameters.awbNumbers || ''}${
    parameters.moneyTransferNumbers
      ? `${addComma ? ', ' : ''} ${parameters.moneyTransferNumbers}`
      : ''
  }`;

  const {
    time,
    awbNumbers,
    moneyTransferNumbers,
    idClient,
    idUser,
    hashKey,
    hashKey2,
    idEeUser,
    Clients,
    clientPhone,
    sourceApp,
    compensationSide,
  } = parameters;

  const isMobile = () => {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  };

  const sourceDevice = isMobile() ? 'mobile' : 'desktop';

  const externalData = {
    claimFormUrl: BASE_URL + '/viewClaimByCommonId',
    shipmentNumbers: data?.regardingShipment?.replace(/[\r\n]+/gm, ','),
    claimCode: claimCodeExternal.join(','),
    note: data[FieldsNames.Other] || '',
    compensationSide: data.claimer,
    sourceApp: sourceApp || 'claimsEcont',
    sourceDevice,
  };

  if (skipAuth)
    Object.assign(externalData, {
      feedbackData: {
        time,
        awbNumbers,
        moneyTransferNumbers,
        idClient,
        idUser,
        hashKey,
        hashKey2,
        idEeUser,
        Clients,
        clientPhone,
        sourceApp,
        sourceDevice,
        compensationSide,
      },
    });

  const externalRequest = await API.createClaimExternal(
    {
      ...externalData,
    },
    accessToken,
    parameters
  );

  const internalRequests: any = [];

  if (externalRequest?.data?.result && !externalRequest?.data?.result?.error) {
    for await (const [index, item] of externalRequest.data.result.reclamations.entries()) {
      internalRequests.push(
        await API.createClaimInternal(
          {
            shipmentId: item.number || item.Number?.toString(),
            reasons: claimCodeInternal,
            status: 'Pending',
            deadlineDays: 14,
            description: data[FieldsNames.Other],
            serviceNumber,
          },
          accessToken,
          parameters
        )
      );
    }
  }

  return Promise.all([externalRequest, ...internalRequests]);
};

export const getClaims = (parameters?: any) => {
  const accessToken = getAccessToken();

  return API.getClaimsInternal(accessToken, parameters);
};

export const getClaimsExternal = (parameters: any, status: string, page: string) => {
  const accessToken = getAccessToken();

  return API.getClaimsExternal(accessToken, parameters, status, page);
};

export const updateExternalClaim = async (data: any, parameters?: any, skipAuth?: Boolean) => {
  const accessToken = getAccessToken();

  if (data?.paymentOptions?.type === 'bank') {
    const validateRes = await API.validateBankData(
      data.paymentOptions.params,
      accessToken,
      parameters
    );

    if (validateRes.data.error) {
      return validateRes.data;
    }
  }

  const isMobile = () => {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  };

  const sourceDevice = isMobile() ? 'mobile' : 'desktop';

  Object.assign(data, {
    sourceDevice,
  });

  const {
    time,
    awbNumbers,
    moneyTransferNumbers,
    idClient,
    idUser,
    hashKey,
    hashKey2,
    idEeUser,
    Clients,
    clientPhone,
    sourceApp,
    compensationSide
  } = parameters;

  if (skipAuth) {
    Object.assign(data, {
      feedbackData: {
        time,
        awbNumbers,
        moneyTransferNumbers,
        idClient,
        idUser,
        hashKey,
        hashKey2,
        idEeUser,
        Clients,
        clientPhone,
        sourceApp,
        compensationSide
      },
    });
  }

  const res = await API.updateExternalClaim(data, accessToken, parameters);

  return res.data.result;
};

export const getClaimById = async (id: string, parameters?: any) => {
  const accessToken = getAccessToken();
  const { data } = await API.getClaimById(id, accessToken, parameters);

  let imagesUrlResponse = { data };
  imagesUrlResponse.data = {};
  if (data.message) return null;

  try {
    imagesUrlResponse = await API.geFilesUrl(id, accessToken, parameters);
  } catch (error) {
    console.error(error);
  }

  const files =
    imagesUrlResponse.data.urls?.map((urlItem: string) => ({
      previewUrl: urlItem,
      data: null,
    })) || [];

  return {
    ...data,
    files,
  };
};

export const getClaimByIdExternal = async (id: string, parameters?: any) => {
  const accessToken = getAccessToken();
  const { data } = await API.getClaimByIdExternal(id, accessToken, parameters);

  let imagesUrlResponse = { data };

  imagesUrlResponse.data = {};
  if (data.message) return null;

  try {
    imagesUrlResponse = await API.geFilesUrl(id, accessToken, parameters);
  } catch (error) {
    console.error(error);
  }

  const files = imagesUrlResponse.data.urls?.map((urlItem: string) => urlItem) || [];
  const paths = imagesUrlResponse.data.paths?.map((urlItem: string) => urlItem) || [];

  return {
    ...data.result,
    files,
    paths,
  };
};

export const getClaimByCommonIdExternal = async (id: string, parameters?: any) => {
  const accessToken = getAccessToken();
  const { data } = await API.getClaimByCommonIdExternal(id, accessToken, parameters);

  let imagesUrlResponse = { data };

  imagesUrlResponse.data = {};
  if (data.message) return null;

  try {
    imagesUrlResponse = await API.geFilesUrl(data?.result?.number, accessToken, parameters);
  } catch (error) {
    console.error(error);
  }

  const files = imagesUrlResponse.data.urls?.map((urlItem: string) => urlItem) || [];
  const paths = imagesUrlResponse.data.paths?.map((urlItem: string) => urlItem) || [];

  return {
    ...data.result,
    files,
    paths,
  };
};

export const downloadFile = async (url: string, parameters?: any) => {
  try {
    const accessToken = getAccessToken();
    const result = await API.downloadFileByUrl(url, accessToken, parameters);

    return result.data;
  } catch (err) {
    return null;
  }
};

export const uploadFiles = async (claimId: string, fielsData: any, parameters: any) => {
  const accessToken = getAccessToken();

  var formData = new FormData();
  fielsData.forEach((fileData: any) => {
    formData.append(`files`, fileData.file);
  });

  await API.uploadFiles(claimId, formData, accessToken, parameters);
};

export const createFeedback = async (data: Feedback, parameters: any, skipAuth: boolean) => {
  const accessToken = getAccessToken();

  const {
    time,
    awbNumbers,
    moneyTransferNumbers,
    idClient,
    idUser,
    hashKey,
    hashKey2,
    idEeUser,
    Clients,
    clientPhone,
    sourceApp,
    compensationSide
  } = parameters;

  if (skipAuth) {
    Object.assign(data, {
      feedbackData: {
        time,
        awbNumbers,
        moneyTransferNumbers,
        idClient,
        idUser,
        hashKey,
        hashKey2,
        idEeUser,
        Clients,
        clientPhone,
        sourceApp,
        compensationSide
      },
    });
  }

  const internalRequest = await API.createFeedback(data, accessToken, parameters);

  return Promise.all([internalRequest]);
};

export const getFeedbackByClaimId = async (claimId: string, parameters?: any) => {
  const accessToken = getAccessToken();
  const { data } = await API.getFeedbackByClaimId(claimId, accessToken, parameters);

  return {
    ...data,
  };
};
