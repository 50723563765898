import { useEffect, useState } from 'react';
import 'twin.macro';
import useAuthHook from '../hooks/use-auth-hook';
import useQuery from '../hooks/use-query-hook';
import useAuthRedirect from '../hooks/use-auth-redirect-hook';
import routePaths from '../route-paths';
import AlertPopup from '../components/layout/AlertPopup';
import ReturnForm from '../components/features/ReturnForm';

import { createClaim, uploadFiles, hasSession } from '../services/actions';

import { ReturnFormData, FieldsNames } from '../types/Form';
import { toast } from 'react-toastify';
import SuccessTooltipIcon from '../components/icons/SuccessTooltipIcon';
import LoadingSpinner from '../components/common/LoadingSpinner';
import Wrapper from '../components/layout/Wrapper';
import { useTranslation } from 'react-i18next';

const initialState: ReturnFormData = {
  [FieldsNames.Checkbox1]: false,
  [FieldsNames.Checkbox2]: false,
  [FieldsNames.Checkbox3]: false,
  [FieldsNames.Checkbox4]: false,
  [FieldsNames.Claimer]: '',
  [FieldsNames.RegardingShipment]: '',
  [FieldsNames.Other]: '',
  [FieldsNames.Files]: [],
};

const ReturnPage = () => {
  const { t } = useTranslation();
  const [touched, setTouched] = useState<boolean>(false);
  const queryParameters = useQuery();
  const { onRedirect } = useAuthRedirect();
  const skipAuth = !!(
    queryParameters.idUser &&
    queryParameters.hashKey &&
    queryParameters.hashKey2
  );

  const skipAuthFullParameters = !!(
    queryParameters.idUser &&
    queryParameters.hashKey &&
    queryParameters.hashKey2 &&
    (queryParameters.skipNumbers ||
      queryParameters.awbNumbers ||
      queryParameters.moneyTransferNumbers) &&
    queryParameters.time
  );

  const {
    isAuthenticated,
    isMount,
  } = useAuthHook(skipAuth, skipAuthFullParameters);

  const [errorMessagesOnSubmit, setErrorMessagesOnSubmit] = useState(['']);

  const [returnData, setData] = useState<ReturnFormData>(initialState);
  const [shouldRestartData, setShouldRestartData] = useState(false);
  const [loading, setLoading] = useState(false);

  const [success, showSuccess] = useState<string>('');
  const [error, showError] = useState<string>('');

  useEffect(() => {
    return () => {
      localStorage.setItem('showFirstClaimBox', 'false');
    };
  });

  useEffect(() => {
    if (isAuthenticated && isMount) {
      hasSession(queryParameters);
    }
  }, [isAuthenticated, isMount]);

  const handleSaveData = (data: ReturnFormData) => {
    setTouched(true);
    setData(data);
  };

  useEffect(() => {
    if (
      returnData[FieldsNames.Checkbox1] ||
      returnData[FieldsNames.Checkbox2] ||
      returnData[FieldsNames.Checkbox3] ||
      returnData[FieldsNames.Checkbox4]
    )
      handleSubmitData();
  }, [returnData]);

  const setFail = (errorMessage?: string) => {
    setLoading(false);
    showError(
      errorMessage || t('claim_filed_unsuccessfully_please_check_your_internet_connection')
    );
    showSuccess('');
  };

  const handleSubmitData = async () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setLoading(true);
    setShouldRestartData(false);
    try {
      const newClaim = await createClaim(
        returnData as ReturnFormData,
        queryParameters,
        skipAuth || skipAuthFullParameters,
        t
      );

      const externalClaimResult = newClaim[0].data;

      const errorMessages = externalClaimResult.result.errors || externalClaimResult.result.error;

      if (errorMessages && errorMessages.length > 0) {
        setLoading(false);
        setErrorMessagesOnSubmit(Array.isArray(errorMessages) ? errorMessages : [errorMessages]);

        return;
      }

      let shouldContinue = true;

      newClaim.forEach((item: any) => {
        if (item.status !== 200) {
          setFail();
          shouldContinue = false;
        }
      });

      if (!shouldContinue) {
        setLoading(false);
        return;
      }

      const uploadImageRequests: any = [];

      for await (const [index, item] of newClaim.entries()) {
        const newClaimId = item.data.id || item.data.ID;
        const filesData = returnData ? returnData[FieldsNames.Files] : [];
        if (filesData.length > 0) {
          if (item?.data?.shipmentId) {
            uploadImageRequests.push(await uploadFiles(newClaimId, filesData, queryParameters));
          }
        }
      }

      if (uploadImageRequests.length) {
        return Promise.all([...uploadImageRequests]).then((response) => {
          if (!externalClaimResult?.result?.reclamations?.[0]?.userHasAccess) {
            setLoading(false);
            showSuccess(t('your_claim_has_been_successfully_submitted'));
            setData(initialState);
            setShouldRestartData(true);
            setTouched(false);
          } else {
            toast.success(t('you_have_successfully_filed_a_claim'), {
              icon: <SuccessTooltipIcon />,
            });
            if (window && window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  screenName: 'create-claim-page',
                  clientId: '32434242',
                  personName: 'Roberto Delianov',
                  office: {
                    name: 'Plovdiv',
                    postCode: '4141',
                  },
                })
              );
            }
            onRedirect(
              `/viewClaimByCommonId/${externalClaimResult?.result?.reclamations?.[0]?.id}`,
              queryParameters,
              queryParameters
            );
          }
        });
      } else {
        if (!externalClaimResult?.result?.reclamations?.[0]?.userHasAccess) {
          setLoading(false);
          showSuccess(t('your_claim_has_been_successfully_submitted'));
          setData(initialState);
          setShouldRestartData(true);
          setTouched(false);
        } else {
          toast.success(t('you_have_successfully_filed_a_claim'), {
            icon: <SuccessTooltipIcon />,
          });
          if (window && window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                screenName: 'create-claim-page',
                clientId: '32434242',
                personName: 'Roberto Delianov',
                office: {
                  name: 'Plovdiv',
                  postCode: '4141',
                },
              })
            );
          }
          onRedirect(
            `/viewClaimByCommonId/${externalClaimResult?.result?.reclamations?.[0]?.id}`,
            { ...queryParameters, page: 1 },
            { ...queryParameters, page: 1 }
          );
        }
      }
    } catch (err: any) {
      setErrorMessagesOnSubmit([err?.response?.data?.message]);

      if (err?.response?.status !== 409) {
        setLoading(false);
        setFail();
        setLoading(false);
      }
    }
  };

  const renderModal = () => {
    const showModal = success || error;
    const isFailed = !!error;

    if (showModal) {
      return (
        <AlertPopup
          show={isFailed}
          text={t('claim_submitted_unsuccessfully')}
          subText={error}
          onClose={() => {
            showError('');
            setErrorMessagesOnSubmit(['']);
          }}
          onClick={() => {
            showError('');
            setErrorMessagesOnSubmit(['']);
          }}
        />
      );
    }
  };

  const renderSuccessModal = () => {
    if (success) {
      return (
        <AlertPopup
          show={success ? true : false}
          text={t('you_have_successfully_submitted_a_claim')}
          subText={t('the_other_party_is_the_owner_of_the_shipment')}
          subText2={t('from_this_moment_on_he_will_be_able_to_follow_the_claim')}
          onClose={() => {
            showSuccess('');
            setErrorMessagesOnSubmit(['']);
          }}
          onClick={() => {
            showSuccess('');
            setErrorMessagesOnSubmit(['']);
          }}
          type='success'
        />
      );
    }
  };

  if (!isAuthenticated && isMount) {
    return null;
  }

  if (loading) {
    return (
      <Wrapper>
        <LoadingSpinner />
      </Wrapper>
    );
  }

  return (
    <div>
      <>
        <ReturnForm
          initialData={returnData}
          goBack={() => onRedirect(routePaths.myClaims, queryParameters, queryParameters)}
          handleSaveData={handleSaveData}
          awbNumbers={queryParameters.awbNumbers}
          moneyTransferNumbers={queryParameters.moneyTransferNumbers}
          errorMessagesOnSubmit={errorMessagesOnSubmit}
          clearErrorsOnSubmit={() => setErrorMessagesOnSubmit([''])}
          setFail={setFail}
          touched={touched}
          shouldRestartData={shouldRestartData}
        />
      </>
      {renderModal()}
      {renderSuccessModal()}
    </div>
  );
};

export default ReturnPage;
