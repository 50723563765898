import React from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
  fill?: string;
  strokeWidth?: string;
}

function DoneIcon({ className, width, height, fill, strokeWidth }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width || '18'}
      height={height || '13'}
      fill='none'
      viewBox='0 0 18 13'
      className={className || ''}
    >
      <path
        fill={fill || '#339900'}
        stroke={fill || '#339900'}
        strokeWidth={strokeWidth || 0.4}
        d='M17.736.26a.91.91 0 00-1.273 0L5.681 10.862 1.536 6.787a.91.91 0 00-1.272 0 .875.875 0 000 1.252l4.78 4.702a.911.911 0 001.273 0l11.42-11.23a.875.875 0 000-1.252z'
      ></path>
    </svg>
  );
}

export default DoneIcon;
