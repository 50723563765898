import Tabs from '../layout/Tabs';
import ClaimsListContent from '../list/ClaimsListContent';

import { ClaimItemType } from '../../types/Data';
import tw, { css } from 'twin.macro';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

declare global {
  interface Window {
    ReactNativeWebView: any; // 👈️ turn off type checking
  }
}

interface Props {
  listData: ClaimItemType[];
  activePage: number;
  active: number;
  finish: number;
  refetchData: Function;
  setActivePage: Function;
  loading?: boolean;
  setLoading?: Function;
}

const ClaimsList = ({
  listData,
  activePage,
  active,
  finish,
  setActivePage,
  refetchData,
  loading,
  setLoading,
}: Props) => {
  const { t } = useTranslation();

  const getClaimsListOptions = useCallback(
    (
      data: ClaimItemType[],
      activePage: number,
      active: number,
      finish: number,
      setActivePage: Function,
      refetchData: Function
    ) => [
      {
        label: `${t('active')} (${active})`,
        index: 0,
        content: (
          <ClaimsListContent
            activePage={activePage ? activePage - 1 : 0}
            listData={data}
            totalResults={active}
            setActivePage={setActivePage}
            refetchData={refetchData}
            type='unprocessed'
          />
        ),
      },
      {
        label: `${t('finished')} (${finish})`,
        index: 1,
        disabled: finish === 0,
        content: (
          <ClaimsListContent
            activePage={activePage ? activePage - 1 : 0}
            listData={data}
            totalResults={finish}
            setActivePage={setActivePage}
            refetchData={refetchData}
            type='processed'
          />
        ),
      },
    ],
    [listData, activePage]
  );

  return (
    <div tw='font-body'>
      <Tabs
        activeTab={0}
        tabList={getClaimsListOptions(
          listData,
          activePage,
          active,
          finish,
          setActivePage,
          refetchData
        )}
        refetchData={refetchData}
        setActivePage={setActivePage}
        activePage={activePage}
        loading={loading}
        setLoading={setLoading}
        totalResults={active + finish}
      />
    </div>
  );
};

export default ClaimsList;
