import React from 'react';

function LocationIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='#fff' width='14' height='14' viewBox='0 0 32 32'>
      <g style={{ fill: '#fff', opacity: 0, stroke: '#234182' }}>
        <rect style={{ stroke: 'none' }} width='32' height='32' />
        <rect style={{ fill: 'none' }} x='0.5' y='0.5' width='31' height='31' />
      </g>
      <g transform='translate(8.669 1.001)'>
        <path
          style={{
            stroke: '#4877CE',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          d='M7.45,29.8c-1.429,0-4.131-.275-4.131-1.317s2.7-1.313,4.131-1.313,4.131.274,4.131,1.313S8.879,29.8,7.45,29.8Zm0-6.279a.323.323,0,0,1-.289-.177L.732,10.97A8.7,8.7,0,0,1,0,7.471a7.449,7.449,0,1,1,14.9,0,8.529,8.529,0,0,1-.811,3.663L7.74,23.341A.326.326,0,0,1,7.454,23.518Zm0-20.953a4.907,4.907,0,1,0,4.885,4.9A4.9,4.9,0,0,0,7.454,2.566Z'
          transform='translate(0 0)'
        />
      </g>
    </svg>
  );
}

export default LocationIcon;
