export interface File {
  file: any;
  previewUrl: string | null;
  data?: any;
}

export interface UploadFile {
  index: number;
  id: string;
  thumbnailLink: string;
  mimeType: string;
  name: string;
  loading: boolean;
  error: any;
}

export interface UploadFileState {
  [key: number]: UploadFile;
}

export enum FieldsNames {
  Checkbox1 = 'checkbox1',
  Checkbox2 = 'checkbox2',
  Checkbox3 = 'checkbox3',
  Checkbox4 = 'checkbox4',
  Claimer = 'claimer',
  RegardingShipment = 'regardingShipment',
  Other = 'other',
  Files = 'files',
}

export interface ReturnFormData {
  [FieldsNames.Checkbox1]: boolean;
  [FieldsNames.Checkbox2]: boolean;
  [FieldsNames.Checkbox3]: boolean;
  [FieldsNames.Checkbox4]: boolean;
  [FieldsNames.Claimer]: string;
  [FieldsNames.RegardingShipment]?: string;
  [FieldsNames.Other]: string;
  [FieldsNames.Files]: File[];
}

interface CheckboxValue {
  label: string;
  claimCode: string;
}

interface CheckboxValuesTypes {
  [key: string]: CheckboxValue;
}

export const checkboxValues = (t: any): CheckboxValuesTypes => ({
  [FieldsNames.Checkbox1]: {
    label: t('delayed_delivery'),
    claimCode: '0001',
  },
  [FieldsNames.Checkbox2]: {
    label: t('damaged_shipment'),
    claimCode: '0010',
  },
  [FieldsNames.Checkbox3]: {
    label: t('missing_or_incomplete_shipment'),
    claimCode: '0003',
  },
  [FieldsNames.Checkbox4]: {
    label: t('other'),
    claimCode: '0041',
  },
});

export const opinionAgreementRadioValues = (t: any) => [
  {
    label: t('i_agree_with_the_opinion'),
    value: '1',
  },
  {
    label: t('i_dont_agree_with_the_opinion'),
    value: '2',
  },
];
