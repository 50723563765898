import React from 'react';

interface Props {
  className?: string;
  fill?: string;
}

function PlusIcon({ className, fill }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 16 15'
      className={className || ''}
    >
      <path fill={fill || 'gray'} fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
    </svg>
  );
}

export default PlusIcon;
