import moment from 'moment';
import DoneClaimIcon from '../components/icons/DoneClaimIcon';
import EcontomatIcon from '../components/icons/Econtomat';
import OfficeIcon from '../components/icons/Office';
import PendingIcon from '../components/icons/PendingIcon';
import ReceivedIcon from '../components/icons/ReceivedIcon';
import XIcon from '../components/icons/XIcon';

import { Status } from '../types/Data';
import { getDaysRemaining } from './dataFormatter';

export const getStatusIconClaimItem = (status: Status) => {
  switch (status) {
    case Status.Pending:
      return PendingIcon;
    case Status.Received:
      return ReceivedIcon;
    case Status.Reject:
      return XIcon;
    case Status.DoneUnpayed:
    case Status.Done:
      return DoneClaimIcon;
    default:
      return PendingIcon;
  }
};

export const getOfficeIcon = (officeName: string) => {
  if (officeName) {
    if (officeName.includes('еконтомат') || officeName.includes('Еконтомат')) return EcontomatIcon;
    else return OfficeIcon;
  } else return OfficeIcon;
};

export const getStatusTitleClaimItem = (status: Status, t: any) => {
  switch (status) {
    case Status.Pending:
      return {
        color: 'rgb(75 85 99)',
        text: t('we_are_processing_the_complaint'),
      };
    case Status.Received:
      return {
        color: '#4c74cc',
        text: t('you_have_received_an_opinion'),
      };
    case Status.Done:
      return {
        color: '#339900',
        text: t('you_have_received_compensation'),
      };
    case Status.DoneUnpayed:
      return {
        color: '#339900',
        text: t('no_compensation'),
      };
    case Status.Reject:
      return {
        color: '#e41e1a',
        text: t('rejected'),
      };
    default:
      return {
        color: 'rgb(75 85 99)',
        text: t('we_are_processing_the_complaint'),
      };
  }
};

export const getActiveStep = (status: Status) => {
  switch (status) {
    case Status.Pending:
      return 1;
    case Status.Received:
      return 2;
    case Status.DoneUnpayed:
    case Status.Done:
      return 3;
    default:
      return 1;
  }
};

export const getResponseTime = (
  t: any,
  status: Status,
  responseTime: string,
  finishedAt?: string
) => {
  let text = '';
  let tooltipText = '';
  let time: String | Number = '';
  let expired: Boolean = false;

  switch (status) {
    case Status.Pending:
      text = t('we_ll_get_back_to_you_by');
      break;
    case Status.Received:
      text = t('reply_to_us_by');
      tooltipText = t('with_the_expiration_of_the_period_options_is_considered_rejected');
      break;
    case Status.DoneUnpayed:
    case Status.Done:
      text = t('closed_on');
      time = finishedAt || '';
      break;
    default:
      text = t('we_ll_get_back_to_you_by');
      break;
  }

  if (status === Status.Done || status === Status.DoneUnpayed)
    return {
      time,
      text,
      done: true,
    };

  let days = getDaysRemaining(responseTime);

  if (days && days > 0) time = moment(responseTime, 'DD-MM-YYYY').format('DD MMMM, YYYY');
  else if (days === 0) {
    const getExactTime = responseTime.split(' ')[1];
    const splitToHoursAndMinutes = getExactTime.split(':');

    if (
      Number(splitToHoursAndMinutes[0]) < moment().hours() ||
      (Number(splitToHoursAndMinutes[0]) <= moment().hours() &&
        Number(splitToHoursAndMinutes[1]) < moment().minutes())
    ) {
      time = moment(responseTime, 'DD-MM-YYYY').format('DD MMMM, YYYY');
      expired = true;
      tooltipText = t('the_response_time_has_expired_you_can_contact_expert');
    } else {
      time = responseTime.split(' ')[1] + ' ч.';
    }
  } else if (days < 0) {
    time = moment(responseTime, 'DD-MM-YYYY').format('DD MMMM, YYYY');
    expired = true;
    tooltipText = t('the_response_time_has_expired_you_can_contact_expert');
  }

  return {
    text,
    time,
    expired,
    tooltipText,
  };
};

export const formatDateInRecommmendedType = (dateString: String, t: any) => {
  if (!dateString) return;
  let date = dateString.split(' ')?.[0];

  let day = '';
  let month = '';
  let year = '';

  const deconstructDateInParts = date.split('/');

  if (deconstructDateInParts.length !== 3) return date;

  day = deconstructDateInParts[0];

  switch (deconstructDateInParts[1]) {
    case '01':
      month = t('january');
      break;
    case '02':
      month = t('february');
      break;
    case '03':
      month = t('march');
      break;
    case '04':
      month = t('april');
      break;
    case '05':
      month = t('may');
      break;
    case '06':
      month = t('june');
      break;
    case '07':
      month = t('july');
      break;
    case '08':
      month = t('august');
      break;
    case '09':
      month = t('september');
      break;
    case '10':
      month = t('october');
      break;
    case '11':
      month = t('november');
      break;
    case '12':
      month = t('december');
      break;
    default:
      month = t('january');
      break;
  }

  year = deconstructDateInParts[2];

  const formattedDate = day + ' ' + month + ' ' + year + ' ' + t('y.');

  return formattedDate;
};
