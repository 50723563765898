import Dropdown from '../Dropdown/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { LanguageOptions } from '../../../constants';
import 'twin.macro';
import { useTranslation } from 'react-i18next';

const LanguageDropdown = () => {
  const { t, i18n } = useTranslation();
  const handleLanguageChange = (lang: string) => {
    localStorage.setItem('lang', `${lang}`);
    i18n.changeLanguage(lang);
    return window.location.reload()
  };

  return (
    <Dropdown
      options={LanguageOptions(t)}
      onClick={handleLanguageChange}
      icon={
        <div tw='flex items-center gap-2 text-center'>
          <p tw='text-white'>{i18n.language.toUpperCase()}</p>
          <FontAwesomeIcon
            icon={faChevronDown}
            color='#fff'
            style={{ marginTop: '2px', fontSize: '0.9rem' }}
          />
        </div>
      }
    />
  );
};

export default LanguageDropdown;
