import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

interface Response {
  [key: string]: string;
}

const useQuery = (): Response => {
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search, { arrayFormat: 'none' });

  const filteredQueries: Response = {};

  for (let key in parsedQueries) {
    if (parsedQueries[key] && !Array.isArray(parsedQueries[key])) {
      filteredQueries[key] = parsedQueries[key] as string;
    }
  }

  return filteredQueries;
};

export default useQuery;
