import tw from 'twin.macro';

interface DropdownProps {
  children: string;
  onClick: Function;
  isLast?: boolean;
}

const DropdownItem = ({ children, onClick, isLast }: DropdownProps) => {
  return (
    <button
      onClick={() => onClick()}
      css={[
        tw`w-full h-9 text-left 
        border-2 border-grey-800 border-l-0 border-r-0 border-t-0
        hover:(bg-black-light text-white)`,
        isLast && tw`border-b-0`,
      ]}
    >
      {children}
    </button>
  );
};

export default DropdownItem;
