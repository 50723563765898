import { useTranslation } from 'react-i18next';
import ArrowDownIcon from '../../icons/ArrowDownIcon';
import ArrowUpIcon from '../../icons/ArrowUpIcon';

const BankAccountDropdown = ({
  open,
  setOpen,
  onChooseHandler,
  bankAccounts,
  bankAccount,
}: {
  open: boolean;
  setOpen: any;
  onChooseHandler: any;
  bankAccounts?: any;
  bankAccount?: any;
}) => {
  const { t } = useTranslation();
  return (
    <div className='w-full mt-3 relative'>
      <div className='w-full flex flex-col gap-2 justify-center items-start'>
        <div className='text-xs text-black-light font-body'>{t('choose_an_account')}</div>
        <button className='w-full h-9' onClick={() => setOpen(!open)}>
          <div className='w-full p-2 border border-grey-800 rounded-md flex justify-between items-center'>
            <div className='text-xs md:text-sm font-body'>{`${
              bankAccount?.owner
            } - ***${bankAccount?.IBAN.slice(-4)}`}</div>
            <div>
              {open ? (
                <ArrowUpIcon width='20' height='20' fill='rgb(66 66 66)' />
              ) : (
                <ArrowDownIcon width='20' height='20' fill='rgb(66 66 66)' />
              )}
            </div>
          </div>
        </button>
      </div>
      {open && (
        <div className='w-full px-2 absolute top-16 z-50 flex flex-col justify-center items-center border-2 border-grey-800 bg-white'>
          {bankAccounts.map((bankAccount: any, index: number) => (
            <button
              key={index}
              onClick={() => onChooseHandler(bankAccount)}
              className={`w-full py-2 text-sm text-grey-500 border-grey-800 hover:bg-black-light hover:text-white ${
                index !== bankAccounts.length - 1 && 'border-b-2'
              }`}
            >
              {`${bankAccount.owner} - ***${bankAccount.IBAN.slice(-4)}`}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default BankAccountDropdown;
