import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import ButtonWrapper from '../Button/ButtonWrapper';
import TextDocIcon from '../icons/TextDocIcon';
import GrayBackgroundWrapper from '../layout/GrayBackgroundWrapper';

interface Props {
  title?: string;
  onClick: Function;
  className?: string;
}

const FirstClaimBox = ({ onClick, className }: Props) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onClick();
  };
  return (
    <>
      <GrayBackgroundWrapper top='0px' />
      <div
        style={{ height: '70vh' }}
        className={`flex justify-center ${className || 'items-center'}`}
      >
        <div
          style={{ background: 'white' }}
          className='flex justify-center items-center text-center py-10 flex-col rounded-lg max-w-md'
        >
          <TextDocIcon className=' mb-6' />
          <h1 className='text-2xl text-gray-900'>{t('do_you_want_to_file_a_claim')}</h1>
          <span className='text-gray-600 mt-4 w-9/12 mb-10'>
            {t('you_can_alert_us_here_if_your_shipment_is_missing_incomplete_damaged_or_delayed')}
          </span>
          <ButtonWrapper>
            <Button type={'button'} variant={'default'} onClick={handleClick}>
              {t('send_claim')}
            </Button>
          </ButtonWrapper>
        </div>
      </div>
    </>
  );
};

export default FirstClaimBox;
