import useAuthRedirect from '../../../hooks/use-auth-redirect-hook';
import routePaths from '../../../route-paths';
import LeftArrow from '../../icons/LeftArrow';
import RightArrowModernIcon from '../../icons/RightArrowModernIcon';
import { isMobile, isTablet } from 'react-device-detect';

interface Props {
  totalResults: number;
  resultsPerPage: number;
  onChange: (pageNumber: number) => void;
  label: string;
  setActivePageIndex: Function;
  activePageIndex: number;
}

const PaginationWrapper = ({
  totalResults,
  resultsPerPage,
  onChange,
  label,
  activePageIndex,
  setActivePageIndex,
}: Props) => {
  const { onRedirect } = useAuthRedirect();

  const pagesCount = totalResults / resultsPerPage;

  const handleChangeIndex = (index: number) => {
    if (index === activePageIndex) {
      return null;
    }

    if (index === -1) {
      return null;
    }

    if (index === pagesCount) {
      return null;
    }

    if (index > pagesCount) {
      return null;
    }
    onRedirect(routePaths.myClaims, undefined, {
      page: index + 1,
    });

    setActivePageIndex((index + 1).toString());
    onChange(index);
  };

  const renderLeftSide = () => {
    const firstItem = activePageIndex * resultsPerPage + 1;
    const lastItem = resultsPerPage * (activePageIndex + 1);
    const upperLimit = activePageIndex === Math.round(pagesCount - 1) ? totalResults : lastItem;

    return (
      <div className=''>
        <span className='mr-1'>{label}</span>
        <span className='text-black-100'>
          {firstItem} - {upperLimit}
        </span>
        <span className='mx-1'>от</span>
        <span className='text-black-100'>{totalResults}</span>
      </div>
    );
  };

  const renderPagesButton = () => {
    let elements = [];
    let dotIndexLeft = 0;
    let dotIndexRight = 0;
    for (let i = 0; i < pagesCount; i += 1) {
      const isActive = i === activePageIndex;

      if (
        activePageIndex === i ||
        activePageIndex + 1 === i ||
        activePageIndex - 1 === i ||
        i === 0 ||
        i === pagesCount - 1 ||
        pagesCount < 8
      ) {
        elements.push(
          <span
            className={`${
              isActive ? 'text-black-100 mx-2' : 'mx-2'
            } flex justify-center items-center`}
            style={{ width: '20px', height: '20px' }}
            onClick={() => {
              handleChangeIndex(i);
            }}
            key={i}
          >
            {i + 1}
          </span>
        );
      } else if (i < activePageIndex && dotIndexLeft < 1) {
        dotIndexLeft += 1;
        elements.push(
          <span
            className={`${
              isActive ? 'text-black-100 mx-2' : 'mx-2'
            } flex justify-center items-center`}
            style={{ width: '20px', height: '20px' }}
            key={i}
          >
            ...
          </span>
        );
      } else if (i > activePageIndex && dotIndexRight < 1) {
        dotIndexRight += 1;
        elements.push(
          <span
            className={`${
              isActive ? 'text-black-100 mx-2' : 'mx-2'
            } flex justify-center items-center`}
            style={{ width: '20px', height: '20px' }}
            key={i}
          >
            ...
          </span>
        );
      }
    }
    return elements;
  };

  const renderRightSide = () => {
    const paginationAllPages = Array(Math.ceil(pagesCount)).fill(1);
    return (
      <div className='flex justify-center flex-col items-center gap-4 mt-3'>
        <div className='flex items-center cursor-pointer'>
          <span
            onClick={() => {
              handleChangeIndex(activePageIndex - 1);
            }}
            className='flex items-center justify-center h-5 w-5'
          >
            <LeftArrow width='12' height='12' />
          </span>
          <div className='flex justify-evenly flex-wrap'>{renderPagesButton()}</div>
          <span
            className='flex items-center justify-center h-5 w-5'
            onClick={() => {
              handleChangeIndex(activePageIndex + 1);
            }}
          >
            <RightArrowModernIcon width='12' height='12' />
          </span>
        </div>
        {pagesCount >= 8 && (
          <select
            name='pagination-all-pages'
            id='pagination-all-pages'
            onChange={(e) => {
              handleChangeIndex(Number(e?.currentTarget?.value) || 0);
            }}
            value={activePageIndex}
            className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:outline-none block w-28 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:outline-none dark:focus:ring-0'
          >
            {paginationAllPages.map((value, index) => (
              <option key={index} value={index}>
                {index + 1}
              </option>
            ))}
          </select>
        )}
      </div>
    );
  };

  return (
    <div
      className={`flex mt-5 h-auto items-center text-xs font-bold text-grey-900 flex-wrap ${
        isMobile || isTablet ? 'mb-24 justify-center' : 'mb-0 justify-between'
      }`}
    >
      {renderLeftSide()}
      {renderRightSide()}
    </div>
  );
};

export default PaginationWrapper;
