import { AUTH_LOGIN_URL, BASE_URL } from '../constants';
import routePaths from '../route-paths';

export const securityHandler = (error: string) => {
  if (error === 'Expired Hash')
    window.location.replace((BASE_URL as string) + routePaths.errorExpiredHash);
  else if (error === 'Invalid Hash')
    window.location.replace((BASE_URL as string) + routePaths.errorInvalidHash);
  else if (error === 'Unauthenticated') {
    localStorage.clear();
    window.location.replace(AUTH_LOGIN_URL as string);
  }

  return error;
};
