import { Fragment, useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';

interface Props {
  className?: string;
  tooltipText?: string;
  expired?: Boolean;
}

function TooltipInfoIcon({ className, tooltipText, expired }: Props) {
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    const hideTooltip = () => {
      setIsHover(false);
    };

    window.addEventListener('scroll', hideTooltip);

    return () => window.removeEventListener('scroll', hideTooltip);
  }, []);

  const element = document.getElementById('root');

  const keyed = Math.random() + '' + expired;

  return isMobile || isTablet ? (
    <div key={`${Math.random() + `${expired}`}`}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='18'
        height='18'
        fill={expired ? '#e41e1a' : !expired ? 'rgba(41,66,126,var(--tw-bg-opacity))' : ''}
        className='bi bi-info-circle'
        viewBox='0 0 16 16'
        onClick={() => {
          if (isHover) {
            setIsHover(false);
          } else {
            setIsHover(true);
          }
        }}
      >
        {!isHover && (
          <Fragment>
            <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
            <path d='m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z' />{' '}
          </Fragment>
        )}
        {isHover && (
          <path d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z' />
        )}
      </svg>

      {isHover && <div className='tooltip tooltip--top' data-tooltip={tooltipText}></div>}
      {isHover && (
        <div
          className={`absolute top-0 left-0 w-full h-full bg-transparent`}
          onClick={() => {
            setIsHover(false);
          }}
        ></div>
      )}
    </div>
  ) : (
    <div key={Math.random() + '' + expired}>
      <svg
        key={Math.random() + '' + expired}
        data-tip={tooltipText}
        data-id={Math.random() + '' + expired}
        data-for={keyed}
        xmlns='http://www.w3.org/2000/svg'
        width='18'
        height='18'
        fill={expired ? '#e41e1a' : !expired ? 'rgba(41,66,126, 1)' : ''}
        className='bi bi-info-circle svg-tooltip'
        viewBox='0 0 16 16'
      >
        <path id='l1' d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
        <path id='l2' d='M6.392 6.011h2.076v5.928h-1V7.011H6.392ZM7.5 3.743h1v1.319h-1Z' />
      </svg>
      {ReactDOM.createPortal(
        <ReactTooltip
          key={Math.random() + '' + expired}
          type='light'
          className='w-2/4 dateTermsTooltipIDclass'
          id={keyed}
        />,
        element!
      )}
    </div>
  );
}

export default TooltipInfoIcon;
