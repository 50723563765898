import Pagination from '../layout/pagination';
import { ClaimItemType } from '../../types/Data';
import ClaimItem from './ClaimItem';
import GrayBackgroundWrapper from '../layout/GrayBackgroundWrapper';
import { useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import FirstClaimBox from '../common/FirstClaimBox';
import useAuthRedirect from '../../hooks/use-auth-redirect-hook';
import useQuery from '../../hooks/use-query-hook';
import routePaths from '../../route-paths';

interface Props {
  listData: ClaimItemType[];
  activePage: number;
  totalResults: number;
  setActivePage: Function;
  refetchData: Function;
  type: string;
}

const ClaimsListContent = ({
  listData,
  activePage,
  totalResults,
  refetchData,
  type,
  setActivePage,
}: Props) => {
  const { onRedirect } = useAuthRedirect();
  const { page, ...queryParameters } = useQuery();
  const renderClaimItem = (props: ClaimItemType) => (
    <div className='' key={props.number}>
      <ClaimItem data={props} />
    </div>
  );
  const refetchDataClaims = (page: string) => {
    refetchData(type, page);
  };

  useEffect(() => {
    if (isMobile || isTablet) {
      if (listData && !listData.length) {
        localStorage.removeItem('showBottomSheet');
      } else {
        localStorage.setItem('showBottomSheet', 'true');
      }
    } else {
      localStorage.removeItem('showBottomSheet');
    }
  }, [listData]);

  const limit = 10;
  return (
    <div>
      <GrayBackgroundWrapper top={'0.1rem'} />
      {listData && listData.length ? (
        <Pagination
          activePage={activePage}
          listData={listData}
          limitPerPage={limit}
          label='Показване на резултати'
          renderComponent={renderClaimItem}
          totalResults={totalResults}
          setActivePage={setActivePage}
          refetchData={refetchDataClaims}
        />
      ) : (
        <FirstClaimBox
          onClick={() => {
            onRedirect(
              routePaths.root,
              { ...queryParameters, skipNumbers: 'true' },
              { ...queryParameters, skipNumbers: 'true' }
            );
          }}
          className={`items-start ${isMobile || isTablet ? 'mt-28' : 'mt-52'}`}
        />
      )}
    </div>
  );
};

export default ClaimsListContent;
