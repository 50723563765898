import React from 'react';

interface Props {
  className?: string;
}

function PendingIcon({ className }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      fill='none'
      viewBox='0 0 18 15'
      className={className || ''}
    >
      <path fill='rgb(75 85 99)' d='M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z' />
      <path fill='rgb(75 85 99)' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z' />
    </svg>
  );
}

export default PendingIcon;
