import 'twin.macro';
import AlertPopup from '../components/layout/AlertPopup';
import Wrapper from '../components/layout/Wrapper';
import { useTranslation } from 'react-i18next';

const ErrorInvalidHash = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <AlertPopup
        show={true}
        text={t('error')}
        subText={t('please_check_that_the_information_you_have_entered_is_correct')}
        onClick={() => window.location.reload()}
        phoneText={t('contact_our_colleague')}
        primaryButtonText={t('try_again')}
        secondaryButton={true}
      />
    </Wrapper>
  );
};

export default ErrorInvalidHash;
