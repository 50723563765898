import React from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
  fill?: string;
  strokeWidth?: string;
}

function DoneClaimIcon({ className, width, height, fill, strokeWidth }: Props) {
  return (
    <svg
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      fill-rule='evenodd'
      clip-rule='evenodd'
      width={width || '30'}
      height={height || '30'}
      className={className || ''}
    >
      <path
        fill={fill || '#339900'}
        d='M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm7 7.457l-9.005 9.565-4.995-5.865.761-.649 4.271 5.016 8.24-8.752.728.685z'
      />
    </svg>
  );
}

export default DoneClaimIcon;
