import { useState } from 'react';
import Modal from 'react-modal';
import LocationIcon from '../icons/LocationIcon';
import tw from 'twin.macro';
import XIcon from '../icons/XIcon';
import { useTranslation } from 'react-i18next';

const modalStyle = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.486)',
    zIndex: 1111122332,
  },
  content: {
    border: 'none',
    borderRadius: '8px',
    top: '50%',
    left: '50%',
    minWidth: '320px',
    maxWidth: '1200px',
    width: '90%',
    height: 'calc(100% - 200px)',
    overfloY: 'scroll',
    zIndex: 0,
    transform: 'translate(-50%, -50%)',
  },
};

interface Props {
  showMap: boolean;
  setShowMap: Function;
  setOffice: Function;
  activatorText: string;
}

const OfficeLocator = ({ activatorText, showMap, setShowMap, setOffice }: Props) => {
  const { t } = useTranslation();
  const [isOpenMainModal, setIsOpenMainModal] = useState(false);

  window.addEventListener('message', (e) => {
    setOffice(e.data?.office);
    setIsOpenMainModal(false);
  });

  const handleBack = () => {
    setIsOpenMainModal(false);
  };

  return (
    <div className='mb-2'>
      <div onClick={() => setIsOpenMainModal(true)} tw='cursor-pointer'>
        <span
          tw='text-[11px] text-center flex ml-[2.0rem] flex items-center'
          style={{ fontFamily: 'Open Sans, Regular', color: '#4877CE' }}
        >
          {activatorText} <LocationIcon />{' '}
        </span>
      </div>
      <Modal
        isOpen={isOpenMainModal}
        onRequestClose={() => setIsOpenMainModal(false)}
        contentLabel='Give opinion feedback modal'
        style={modalStyle}
      >
        <div>
          <div tw='h-[40px] flex items-center px-5'>
            <span
              tw='w-full font-bold text-[18px]'
              style={{ fontFamily: 'Open Sans, Regular', color: '#212121' }}
            >
              {t('where_would_you_like_us_to_send_the_compensation_amount')}
            </span>
            <XIcon
              width='24'
              height='24'
              fill='black'
              tw='font-bold cursor-pointer'
              onClick={handleBack}
            />
          </div>
          <iframe
            title='Locator'
            scrolling='yes'
            frameBorder='0'
            style={{
              position: 'fixed',
              top: '80px',
              left: 0,
              bottom: 0,
              right: 0,
              width: '100%',
              height: 'calc(100% - 80px)',
              border: 'none',
              margin: 0,
              padding: 0,
              zIndex: 22332,
              overflow: 'scroll',
            }}
            allowFullScreen
            src='https://officelocator.econt.com/?shopUrl=https://example.officelocator.econt.com&filterOut=ekontomat'
          ></iframe>
        </div>
      </Modal>
    </div>
  );
};

export default OfficeLocator;
