import { isMobile, isTablet } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import tw from 'twin.macro';
import useAuthRedirect from '../../hooks/use-auth-redirect-hook';
import useQuery from '../../hooks/use-query-hook';
import routePaths from '../../route-paths';
import Button from '../Button/Button';
import ButtonWrapper from '../Button/ButtonWrapper';
import FooterIcon from '../icons/FooterIcon';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  const { onRedirect } = useAuthRedirect();
  const queryParameters = useQuery();
  const location = useLocation();
  const currentYear = new Date().getFullYear();

  const handleClick = () => {
    onRedirect(routePaths.root, queryParameters, queryParameters);
  };

  const showBottomSheet = localStorage.getItem('showBottomSheet');

  return (
    <>
      {(isMobile || isTablet) && location.pathname === routePaths.myClaims && showBottomSheet && (
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            backgroundColor: 'white',
            height: '100px',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            display: 'flex',
            justifyContent: 'center',
            zIndex: 0,
            padding: '20px',
          }}
        >
          <ButtonWrapper>
            <Button type={'submit'} variant='default' onClick={handleClick}>
              {t('new_claim')}
            </Button>
          </ButtonWrapper>
        </div>
      )}
      <div
        id='footer-id'
        css={[
          { display: isMobile || isTablet ? 'none' : 'block', zIndex: 33434, position: 'absolute' },
          tw`p-5 bg-grey-400 w-full z-50`,
        ]}
      >
        <div tw='mobile:(flex-col-reverse flex-nowrap) laptop:(flex-row px-20 justify-between) h-full flex text-xs gap-4 flex-wrap'>
          <div tw='flex items-center justify-center mobile:(border-solid border-0 border-t border-white pt-4) laptop:(border-0 p-0)'>
            <FooterIcon />
            <span tw='mobile:(leading-5) laptop:(leading-4) ml-4 font-body text-[0.8rem] text-grey-100'>
              {t('all_rights_reserved')} &copy; {currentYear} - {t('econt_express_ad')}
            </span>
          </div>
          <div tw='flex mobile:(flex-col justify-start gap-2) laptop:flex-row'>
            <a
              tw='mobile:(leading-5) laptop:(text-xs leading-4 ml-4) font-body text-grey-500 text-[0.8rem]'
              href='https://www.econt.com/client-center'
              target='_blank'
              rel='noreferrer'
            >
              {t('customer_center')}
            </a>
            <span tw='mobile:(leading-5 hidden ml-4) laptop:(leading-4 flex ml-0) font-body text-grey-500'>
              |
            </span>
            <a
              tw='mobile:(leading-5) laptop:(text-xs leading-4 ml-0) font-body text-grey-500 text-[0.8rem]'
              href='https://www.econt.com/econt-express/terms-of-use'
              target='_blank'
              rel='noreferrer'
            >
              {t('terms_of_use_this_site')}
            </a>
            <span tw='mobile:(leading-5 hidden ml-4) laptop:(leading-4 flex ml-0) font-body text-grey-500'>
              |
            </span>
            <a
              tw='mobile:(leading-5) laptop:(text-xs leading-4 ml-0) font-body text-grey-500 text-[0.8rem]'
              href='https://www.econt.com/econt-express/privacy-policy'
              target='_blank'
              rel='noreferrer'
            >
              {t('personal_data_policy')}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
