import React from 'react';

import tw, { css } from 'twin.macro';

interface Props {
  value?: string;
  name: string;
  label?: string;
  large?: boolean;
  placeholder?: string;
  error?: string;
  onChange: (e: React.FormEvent<HTMLTextAreaElement>) => void;
}

const TextareaField = ({ value, large, name, label, placeholder, onChange, error }: Props) => {
  return (
    <label tw='flex flex-wrap items-center cursor-pointer relative' htmlFor={name}>
      {label && (
        <span tw='block w-full pb-1 font-light text-sm font-body text-black-100'>{label}</span>
      )}
      <textarea
        css={[
          tw`mobile:(p-1.5) tablet:(p-1.5) laptop:(p-3.5) w-full border-grey-light font-body font-normal text-sm text-black-100 border rounded-md placeholder-grey-400 resize-none`,
          css`
            &::placeholder {
              ${tw`text-[0.9rem] font-light italic text-gray-400`}
            }
          `,
          large && tw`h-[10rem]`,
          error && tw`border-red-500`,
        ]}
        name={name}
        id={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      {error && <p tw='text-red-100 text-sm mt-2'>{error}</p>}
    </label>
  );
};

export default TextareaField;
