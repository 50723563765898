import 'twin.macro';
import AlertPopup from '../components/layout/AlertPopup';
import Wrapper from '../components/layout/Wrapper';
import { useTranslation } from 'react-i18next';

const ErrorExpiredHash = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <AlertPopup
        show={true}
        text={t('error')}
        subText={t('keys_have_expired')}
        onClick={() => window.location.reload()}
      />
    </Wrapper>
  );
};

export default ErrorExpiredHash;
