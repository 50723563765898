import React from 'react';

interface Props {
  className?: string;
}

function SuccessTooltipIcon({ className }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='#339900'
      viewBox='0 0 24 24'
      className={className || ''}
    >
      <g id='Group_28669' data-name='Group 28669' transform='translate(-168 -823)'>
        <g
          id='Ellipse_1628'
          data-name='Ellipse 1628'
          transform='translate(168 823)'
          fill='#fff'
          stroke='#2f9d28'
          stroke-width='1'
        >
          <circle cx='12' cy='12' r='12' stroke='none' />
          <circle cx='12' cy='12' r='11.5' fill='none' />
        </g>
        <path
          id='Path_32046'
          data-name='Path 32046'
          d='M-1186.812-4691.688l3.553,3.514,7.568-7.568'
          transform='translate(1361.582 5526.959)'
          fill='none'
          stroke='#2f9d28'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='1'
        />
      </g>
    </svg>
  );
}

export default SuccessTooltipIcon;
