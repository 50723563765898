import axios from 'axios';
import { EXTERNAL_API, INTERNAL_API } from '../constants';
import { Feedback } from '../types/Feedback';
import { securityHandler } from '../utils/securityHandler';

export const API_ENDPOINS = {
  external: EXTERNAL_API as string,
  internal: INTERNAL_API as string,
};

export const signIn = (accountCode: string) => {
  return axios(`${API_ENDPOINS.internal}/auth/sign-in`, {
    method: 'post',
    data: { code: accountCode },
  }).catch((e) => {
    securityHandler(e.response?.data?.error || 'error');

    return e.response;
  });
};

export interface ParametersData {
  awbNumbers: string;
  moneyTransferNumbers: string;
  idClient: string;
  idUser: string;
  time: number | null;
  hashKey: string;
  hashKey2: string;
  shipmentNumbers?: string;
  sourceApp?: string;
  compensationSide?: string;
}
export interface CreateClaimExternal {
  feedbackData?: ParametersData;
  shipmentNumbers?: string;
  claimCode?: string;
  note?: string;
  claimFormUrl?: string;
  clientPhone?: string;
  compensationSide: string;
}

export interface CreateClaimInternal {
  shipmentId?: string;
  reasons: string[];
  status: string;
  deadlineDays: number;
  description?: string;
  serviceNumber?: string;
  regardingShipment?: string;
  claimer?: string;
}

export interface UpdateInternalClaimNumber {
  shipmentId: string;
}

const getHeaderParameters = (accessToken: string, parameters: any) => {
  const headers: any = {
    'x-oauth-token': accessToken,
  };

  Object.keys(parameters).forEach((key) => {
    if (
      key === 'awbNumbers' ||
      key === 'idClient' ||
      key === 'time' ||
      key === 'Clients' ||
      key === 'idUser' ||
      key === 'idEeUser' ||
      key === 'clientPhone' ||
      key === 'hashKey' ||
      key === 'hashKey2' ||
      key === 'sourceApp' ||
      key === 'compensationSide'
    )
      headers[key] = parameters[key];
    else return;
  });

  return headers;
};

export const createClaimExternal = (
  data: CreateClaimExternal,
  accessToken: string,
  parameters?: ParametersData
) => {
  const headers = getHeaderParameters(accessToken, parameters);

  return axios(`${API_ENDPOINS.internal}/claims/external`, {
    method: 'post',
    headers,
    data: {
      ...data,
      lang: localStorage.getItem('lang') || 'bg',
    },
  }).catch((e) => {
    securityHandler(e.response?.data?.error || 'error');

    return e.response;
  });
};

export const hasSession = (accessToken: string, parameters?: ParametersData) => {
  const headers = getHeaderParameters(accessToken, parameters);

  return axios(`${API_ENDPOINS.internal}/claims/session`, {
    method: 'get',
    headers,
  }).catch((e) => {
    securityHandler(e.response?.data?.error || 'error');

    return e.response;
  });
};

export const download = (accessToken: string, parameters: ParametersData, url: string) => {
  const headers = getHeaderParameters(accessToken, parameters);
  return axios(`${API_ENDPOINS.internal}/upload/download`, {
    method: 'post',
    headers,
    data: {
      url,
    },
  }).catch((e) => {
    securityHandler(e.response?.data?.error || 'error');

    return e.response;
  });
};

export const createClaimInternal = (
  data: CreateClaimInternal,
  accessToken: string,
  parameters?: ParametersData
) => {
  const headers = getHeaderParameters(accessToken, parameters);

  return axios(`${API_ENDPOINS.internal}/claims`, {
    method: 'post',
    headers,
    data,
  }).catch((e) => {
    securityHandler(e.response?.data?.error || 'error');

    return e.response;
  });
};

export const updateClaimNumberInternal = (
  data: UpdateInternalClaimNumber,
  id: number,
  accessToken: string,
  parameters?: ParametersData
) => {
  const headers = getHeaderParameters(accessToken, parameters);

  return axios(`${API_ENDPOINS.internal}/claims/${id}`, {
    method: 'put',
    headers,
    data,
  }).catch((e) => {
    securityHandler(e.response?.data?.error || 'error');

    return e.response;
  });
};

export const getClaimsExternal = (
  accessToken: string,
  parameters: string,
  status: string,
  page: string
) => {
  const headers = getHeaderParameters(accessToken, parameters);
  const data = !accessToken
    ? {
        feedbackData: parameters,
        status,
        page,
      }
    : { status, page };
  return axios(`${API_ENDPOINS.internal}/claims/fetchExternal`, {
    method: 'post',
    headers,
    data,
  }).catch((e) => {
    securityHandler(e.response?.data?.error || 'error');

    return e.response;
  });
};

export const getClaimsInternal = (accessToken: string, parameters?: ParametersData) => {
  const headers = getHeaderParameters(accessToken, parameters);

  return axios(`${API_ENDPOINS.internal}/claims`, {
    method: 'get',
    headers,
    params: {
      start: 0,
      end: 9999,
    },
  }).catch((e) => {
    securityHandler(e.response?.data?.error || 'error');

    return e.response;
  });
};

export const getClaimById = (id: string, accessToken: string, parameters?: ParametersData) => {
  const headers = getHeaderParameters(accessToken, parameters);

  return axios(`${API_ENDPOINS.internal}/claims/${id}`, {
    method: 'get',
    headers,
  }).catch((e) => {
    securityHandler(e.response?.data?.error || 'error');

    return e.response;
  });
};

export const getClaimByIdExternal = (
  claimNumber: string,
  accessToken: string,
  parameters?: ParametersData
) => {
  const headers = getHeaderParameters(accessToken, parameters);

  const data = !accessToken
    ? {
        reclamationNum: claimNumber,
        feedbackData: parameters,
        lang: localStorage.getItem('lang') || 'bg',
      }
    : { reclamationNum: claimNumber, lang: localStorage.getItem('lang') || 'bg' };
  return axios(`${API_ENDPOINS.internal}/claims/fetchExternalById`, {
    method: 'post',
    headers,
    data: {
      ...data,
    },
  }).catch((e) => {
    securityHandler(e.response?.data?.error || 'error');

    return e.response;
  });
};

export const getClaimByCommonIdExternal = (
  claimId: string,
  accessToken: string,
  parameters?: ParametersData
) => {
  const headers = getHeaderParameters(accessToken, parameters);

  const data = !accessToken
    ? {
        reclamationID: claimId,
        feedbackData: parameters,
        lang: localStorage.getItem('lang') || 'bg',
      }
    : { reclamationID: claimId, lang: localStorage.getItem('lang') || 'bg' };
  return axios(`${API_ENDPOINS.internal}/claims/fetchExternalByCommonId`, {
    method: 'post',
    headers,
    data: {
      ...data,
    },
  }).catch((e) => {
    securityHandler(e.response?.data?.error || 'error');

    return e.response;
  });
};

export const updateExternalClaim = (data: any, accessToken: string, parameters?: any) => {
  const headers = getHeaderParameters(accessToken, parameters);

  return axios(`${API_ENDPOINS.internal}/claims/updateExternalClaim`, {
    method: 'post',
    headers,
    data: {
      ...data,
      lang: localStorage.getItem('lang') || 'bg',
    },
  }).catch((e) => {
    securityHandler(e.response?.data?.error || 'error');

    return e.response;
  });
};

export const uploadFiles = (
  claimId: string,
  files: any,
  accessToken: string,
  parameters?: ParametersData
) => {
  const headers = getHeaderParameters(accessToken, parameters);

  return axios
    .put(`${API_ENDPOINS.internal}/upload/claimFiles/${claimId}`, files, {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      },
    })
    .catch((e) => {
      securityHandler(e.response?.data?.error || 'error');

      return e.response;
    });
};

export const uploadImage = (url: string, data: any) => {
  return axios(url, {
    method: 'put',
    data,
  }).catch((e) => {
    securityHandler(e.response?.data?.error || 'error');

    return e.response;
  });
};

export const geFilesUrl = (
  shipmentId: string,
  accessToken: string,
  parameters?: ParametersData
) => {
  const headers = getHeaderParameters(accessToken, parameters);

  return axios(`${API_ENDPOINS.internal}/upload/claimFiles/${shipmentId}`, {
    method: 'get',
    headers,
  }).catch((e) => {
    securityHandler(e.response?.data?.error || 'error');

    return e.response;
  });
};

export const downloadFileByUrl = (
  url: string,
  accessToken: string,
  parameters?: ParametersData
) => {
  const headers = getHeaderParameters(accessToken, parameters);

  return axios(url, {
    method: 'get',
    responseType: 'blob',
    headers,
  }).catch((e) => {
    securityHandler(e.response?.data?.error || 'error');

    return e.response;
  });
};

export const createFeedback = (
  data: Feedback,
  accessToken: string,
  parameters?: ParametersData
) => {
  const headers = getHeaderParameters(accessToken, parameters);

  return axios(`${API_ENDPOINS.internal}/feedback`, {
    method: 'post',
    headers,
    data,
  });
};

export const getFeedbackByClaimId = (
  claimId: string,
  accessToken: string,
  parameters?: ParametersData
) => {
  const headers = getHeaderParameters(accessToken, parameters);

  return axios(`${API_ENDPOINS.internal}/feedback/${claimId}`, {
    method: 'get',
    headers,
  });
};

export const validateBankData = (
  data: Feedback,
  accessToken: string,
  parameters?: ParametersData
) => {
  const headers = getHeaderParameters(accessToken, parameters);

  return axios(`${API_ENDPOINS.internal}/claims/validateBankData`, {
    method: 'post',
    headers,
    data,
  });
};

export const checkRegisteredPhoneForEcoints = (
  data: any,
  accessToken: string,
  parameters?: ParametersData
) => {
  const headers = getHeaderParameters(accessToken, parameters);

  return axios(`${API_ENDPOINS.internal}/claims/checkRegisteredPhoneForEcoints`, {
    method: 'post',
    headers,
    data,
  });
};
