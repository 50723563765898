import Dropdown from '../Dropdown';

import BurgerIcon from '../../icons/BurgerIcon';
import { NavigationOptions } from '../../../constants';
import 'twin.macro';

const MobileNavigationDropdown = () => {
  const handleRedirect = (url: string) => {
    window.location.replace(url);
  };

  return (
    <Dropdown
      options={NavigationOptions}
      onClick={handleRedirect}
      icon={<BurgerIcon className='text-3xl' />}
    />
  );
};

export default MobileNavigationDropdown;
