import React from 'react';
import tw, { css } from 'twin.macro';

interface Props {
  children: JSX.Element | string;
  type?: 'submit' | 'button' | 'reset';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  variant?: 'default' | 'compact' | 'mini' | 'cool' | 'cool_gray-blue' | 'cool-secondary' | 'cool-auto-width';
  width?: string;
}

const Button = ({ type, children, onClick, disabled, variant, width }: Props) => {
  const variantDefault = [
    css`
      width: 306px;
      height: 2.5rem;
      border-radius: 29px;
    `,
    tw`block text-white font-light text-center mx-auto border bg-blue-100 focus:outline-none focus:ring-0`,
    disabled && tw`bg-blue-disabled`,
  ];

  const variantCompact = [
    css`
      width: 306px;
      height: 2.25rem;
      border-radius: 50px;
    `,
    tw`block text-sm text-white font-body text-center bg-blue-100 focus:outline-none focus:ring-0`,
    disabled && tw`bg-blue-disabled`,
  ];

  const variantCool = [
    css`
      width: 256px;
      height: 2.25rem;
      border-radius: 50px;

      @media (max-width: 768px) {
        width: 100%;
      }
    `,
    tw`block text-sm text-white font-body text-center bg-blue-100 focus:outline-none focus:ring-0`,
    disabled && tw`bg-blue-disabled`,
  ];

  const variantCoolAutoWidth = [
    css`
      width: 100%;
      border-radius: 50px;
      height: 2.25rem;
    `,
    tw`block text-sm text-white font-body text-center bg-blue-100 focus:outline-none focus:ring-0`,
    disabled && tw`bg-blue-disabled`,
  ];

  const variantCoolSecondary = [
    css`
      width: 256px;
      height: 2.25rem;
      border-radius: 50px;

      @media (max-width: 768px) {
        width: 100%;
      }
    `,
    tw`block text-sm text-blue-500 font-body text-center border border-blue-500 focus:outline-none focus:ring-0`,
    disabled && tw`bg-blue-disabled`,
  ];

  const variantCool_GrayBlue = [
    css`
      width: 140px;
      height: 2rem;
      border-radius: 50px;

      @media (max-width: 768px) {
        width: 100%;
      }
    `,
    tw`block text-[13px] text-white font-body text-center bg-blue-100 focus:outline-none focus:ring-0`,
    disabled && tw`bg-grey-600`,
  ];

  const variantMini = [
    css`
      width: 100%;
      border-radius: 50px;
    `,
    tw`block text-sm text-white font-body text-center bg-blue-100 p-2 focus:outline-none focus:ring-0`,
    disabled && tw`bg-blue-disabled`,
  ];

  const handleVariants = () => {
    switch (variant) {
      case 'default':
        return variantDefault;
      case 'compact':
        return variantCompact;
      case 'mini':
        return variantMini;
      case 'cool':
        return variantCool;
      case 'cool_gray-blue':
        return variantCool_GrayBlue;
      case 'cool-secondary':
        return variantCoolSecondary;
      case 'cool-auto-width':
        return variantCoolAutoWidth;
      default:
        return variantDefault;
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!disabled && onClick) {
      onClick(e);
    }
  };

  return (
    <button
      type={type || 'button'}
      onClick={handleClick}
      disabled={disabled}
      css={handleVariants()}
    >
      {children}
    </button>
  );
};

export default Button;
