import React from 'react';

interface Props {
  className?: string;
}

function VideoIcon({ className }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='12'
      fill='none'
      viewBox='0 0 18 12'
      className={className || ''}
    >
      <path
        fill='#29427E'
        d='M17.813 1.646a.384.384 0 00-.377-.007l-4.13 2.243V2.536A1.904 1.904 0 0011.399.64H1.907A1.904 1.904 0 000 2.536v7.488a1.904 1.904 0 001.907 1.896h9.492a1.904 1.904 0 001.907-1.896V8.702l4.13 2.243a.383.383 0 00.564-.333v-8.64a.379.379 0 00-.187-.326zm-5.27 8.378c0 .628-.513 1.137-1.144 1.138H1.907c-.632-.001-1.144-.51-1.144-1.138V2.536c0-.628.512-1.137 1.144-1.138h9.492c.631.001 1.143.51 1.144 1.138v7.488zm4.694-.05l-3.931-2.136V4.746l3.931-2.135v7.362z'
      ></path>
    </svg>
  );
}

export default VideoIcon;
