import React from 'react';
import 'twin.macro';
import PlusIcon from '../../icons/PlusIcon';
import { useTranslation } from 'react-i18next';

interface Props {
  children?: JSX.Element;
}

const DefaultView = ({ children }: Props) => {
  const { t } = useTranslation();

  return (
    <div tw='flex flex-wrap items-center justify-center box-border p-5'>
      <div tw='flex items-center justify-center'>
        {!children && <PlusIcon />}
        {children}
      </div>
      {!children && (
        <p tw='text-gray-400 italic w-full text-center text-[0.9rem] font-light font-body'>
          {t('attach_a_file')}
        </p>
      )}
    </div>
  );
};

export default DefaultView;
