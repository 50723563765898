import { StepperData } from '../components/common/StepperComponent';
import routePaths from '../route-paths';

export const {
  REACT_APP_BASE_URL: BASE_URL,
  REACT_APP_AUTH_LOGIN_URL: AUTH_LOGIN_URL,
  REACT_APP_EXTERNAL_API: EXTERNAL_API,
  REACT_APP_INTERNAL_API: INTERNAL_API,
  REACT_APP_AUTH_LOGOUT_URL: AUTH_LOGOUT_URL,
} = process.env;

// export const BASE_URL = 'http://localhost:3000';
// export const AUTH_LOGIN_URL =
//   'https://login-demo.econt.com/oauth2/auth?client_id=CgjuBbY6u&response_type=code&redirect_uri=https://claims-demo.econt.com';
// export const EXTERNAL_API = 'https://demo.econt.com/ee/json_rpc.php';
// export const INTERNAL_API = 'http://localhost:4000';
// export const AUTH_LOGOUT_URL =
//   'https://login-demo.econt.com/oauth2/logout?client_id=CgjuBbY6u&redirect_uri=https://claims-demo.econt.com';

export interface Option {
  label: string;
  value: string;
}
export const NavigationOptions: Option[] = [
  {
    label: 'econt.com',
    value: 'https://econt.com',
  },
  {
    label: 'e-Econt',
    value: 'https://ee.econt.com',
  },
];

export const ProfileOptions = (t: any): Option[] => [
  {
    label: t('my_claims'),
    value: routePaths.myClaims,
  },
  {
    label: t('new_claim'),
    value: routePaths.root,
  },
  {
    label: t('logout'),
    value: 'logout',
  },
];

export const LanguageOptions = (t: any): Option[] => [
  {
    label: t('bulgarian'),
    value: 'bg',
  },
  {
    label: t('english'),
    value: 'en',
  },
  {
    label: t('romanian'),
    value: 'ro',
  },
  {
    label: t('greek'),
    value: 'gr',
  },
];

export const ClaimViewStepperData = (t: any): StepperData[] => [
  {
    label: t('we_are_processing_the_claim'),
  },
  {
    label: t('we_have_sent_a_statement'),
  },
  {
    label: t('we_have_completed_the_claim'),
  },
];
