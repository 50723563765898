interface Props {
  className?: string;
  width?: string;
  height?: string;
  fill?: string;
  viewbox?: string;
}

function LeftArrow({ className, width, height, fill, viewbox }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width || '7'}
      height={height || '7'}
      fill='none'
      viewBox={viewbox || '0 0 18 18'}
      className={className || ''}
    >
      <path
        fill={fill || '#999'}
        fill-rule='evenodd'
        d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'
      />
    </svg>
  );
}

export default LeftArrow;
