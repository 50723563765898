import React from 'react';

interface Props {
  className?: string;
}

function PdfIcon({ className }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      fill='none'
      viewBox='0 0 32 32'
      className={className || ''}
    >
      <path
        fill='#29427E'
        d='M30.905 8.66L22.752.189A.616.616 0 0022.31 0h-12.15a2.468 2.468 0 00-2.467 2.465v8.612H2.768a1.847 1.847 0 00-1.845 1.845v9.233C.923 23.174 1.75 24 2.768 24h4.924v5.54A2.466 2.466 0 0010.158 32h18.453a2.466 2.466 0 002.466-2.46V9.088a.616.616 0 00-.172-.427zM22.46 1.66l6.693 6.954H22.46V1.661zM2.768 22.77a.615.615 0 01-.614-.613v-9.234c0-.339.275-.614.614-.614h16.617c.34 0 .615.275.615.614v9.233a.615.615 0 01-.614.614H2.768zm27.078 6.772c0 .677-.554 1.228-1.236 1.228H10.158a1.234 1.234 0 01-1.235-1.23V24h10.463a1.847 1.847 0 001.845-1.845v-9.234a1.847 1.847 0 00-1.846-1.844H8.924V2.465c0-.68.554-1.234 1.235-1.234H21.23v8c0 .34.276.615.616.615h8v19.695z'
      ></path>
    </svg>
  );
}

export default PdfIcon;
