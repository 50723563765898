import moment from 'moment';
import { Status } from '../types/Data';

export const getLastHistoryElement_time = (history: any) => {
  if (history && history.length) return moment(history[0]?.time).format('DD/MM/YYYY');
  else return '';
};

export const getLastHistoryElement_time_to_response = (history: any) => {
  if (history && history.length)
    return moment(history[0]?.time).add(15, 'days').format('DD/MM/YYYY H:mm');
  else return '';
};

export const getLastClientResponseHistoryElement_time = (history: any) => {
  if (history && history.length) {
    const lastHistoryFromClient = history.find(
      (hist: any) =>
        hist.field.toLowerCase().includes('отговор от клиента') ||
        hist.field.toLowerCase().includes('response from the client') ||
        hist.field.toLowerCase().includes('răspuns din partea clientului') ||
        hist.field.toLowerCase().includes('απάντηση από τον πελάτη')
    );
    if (!lastHistoryFromClient || !lastHistoryFromClient.time) return '';
    return moment(lastHistoryFromClient.time).format('DD/MM/YYYY');
  } else return '';
};

export const isClaimWaitingForEcontResponse = (history: any, paymentOptions?: any) => {
  if (history && history.length) {
    if (
      history[0].field.toLowerCase().includes('отговор от клиента') ||
      history[0].field.toLowerCase().includes('response from the client') ||
      history[0].field.toLowerCase().includes('răspuns din partea clientului') ||
      history[0].field.toLowerCase().includes('απάντηση από τον πελάτη')
    )
      return true;
    else return false;
  } else return false;
};

export const isClaimAggreedWithDecisionThroughEcoints = (
  compensationStatus: any,
  isClientAgreeWithDecision: any,
  paymentOptions: any
) => {
  if (paymentOptions) {
    if (
      compensationStatus === Status.Received &&
      isClientAgreeWithDecision &&
      paymentOptions.type === 'ecoints'
    ) {
      return {
        valid: true,
        status: Status.Received,
      };
    } else if (
      (compensationStatus === Status.Done || compensationStatus === Status.DoneUnpayed) &&
      isClientAgreeWithDecision &&
      paymentOptions.type === 'ecoints'
    ) {
      return {
        valid: true,
        status: compensationStatus,
      };
    } else {
      return {
        valid: false,
        status: Status.Pending,
      };
    }
  } else {
    return {
      valid: false,
      status: Status.Pending,
    };
  }
};

export const isClaimInFirstLevel = (history: any) => {
  if (history && history.length >= 2) return false;
  else return true;
};

export const isClaimReceivedSecondCompensation = (history: any) => {
  if (history && history.length) {
    const lastHistoryFromClient = history.filter(
      (hist: any) =>
        hist.field.toLowerCase().includes('размер на компенсацията') ||
        hist.field.toLowerCase().includes('amount of compensation') ||
        hist.field.toLowerCase().includes('cuantumul despăgubirii') ||
        hist.field.toLowerCase().includes('ποσό της αποζημίωσης')
    );
    if (!lastHistoryFromClient || lastHistoryFromClient.length <= 1) return false;
    return true;
  } else return false;
};

export const isClaimReceivedSecondResponseWithoutCompensation = (
  history: any,
  compensationAmount: any,
  offerCode: any
) => {
  if (history && history.length) {
    const lastHistoryFromClient = history.filter(
      (hist: any) =>
        hist.field.toLowerCase() === 'предложение' ||
        hist.field.toLowerCase() === 'proposal' ||
        hist.field.toLowerCase() === 'propunere' ||
        hist.field.toLowerCase() === 'πρόταση'
    );

    if (
      (compensationAmount === '0.00' || Number(compensationAmount) === 0) &&
      (history[0]?.value?.includes('Клиента приема') ||
        history[0]?.value?.includes('The client accepts') ||
        history[0]?.value?.includes('Clientul acceptă acest') ||
        history[0]?.value?.includes('Ο πελάτης αποδέχεται'))
    )
      return true;
    if (!lastHistoryFromClient || lastHistoryFromClient.length <= 1) return false;
    if (
      compensationAmount === '0.00' ||
      Number(compensationAmount) === 0 ||
      offerCode === '0108' ||
      offerCode === '0102'
    )
      return true;
    return false;
  } else return false;
};

export const structureLastClientResponseData = (
  history: any,
  paymentOptions?: any,
  compensationAmount?: any,
  isClientAgreeWithDecision?: any,
  offerCode?: any,
  ecointsTransactionStatus?: any,
  t?: any
) => {
  if(offerCode === '0107' && ecointsTransactionStatus !== 'expired'){
    return {
      title: t('you_have_successfully_accepted_compensation_via_ecoints'),
    };
  }
  if (!isClientAgreeWithDecision)
    return {
      title: t('last_client_response'),
      line1: t('not_accepeted_compensation'),
    };

  if (paymentOptions && paymentOptions.type) {
    switch (paymentOptions.type) {
      case 'bank':
        return {
          title: t('you_have_claimed_compensation'),
          line1: t('submitted_a_request_for_compensation_via_bank'),
          line2: `IBAN: ${paymentOptions.params.IBAN}`,
          line3: `${t('compensation_value')} ${compensationAmount} ${t('lv.')}`, //     {/* ?.replace('.', ',') */}
        };
      case 'office':
        return {
          title: t('you_have_claimed_compensation'),
          line1: t('filed_a_claim_for_compensation_at_the_office'),
          line2: `${t('selected_office')} ${paymentOptions.params.officeName}`,
          line3: `${t('compensation_value')} ${compensationAmount} ${t('lv.')}`, //      {/* ?.replace('.', ',') */}
        };
      case 'ecoints':
        return {
          title: t('you_have_successfully_accepted_compensation_via_ecoints'),
        };
      default:
        return {
          title: 'in development',
          line1: 'in development 1',
          line2: 'in development 2',
          line3: 'in development 3',
        };
    }
  }
  if (history && history.length) {
    return {
      title: `${history[0].value}`,
      line1: '',
      line2: '',
      line3:
        Number(compensationAmount) > 0
          ? `${t('compensation_value')} ${compensationAmount} ${t('lv.')}` //      {/* ?.replace('.', ',') */}
          : '',
    };
  }

  return {
    title: 'History - in development',
    line1: 'in development',
    line2: 'in development 2',
    line3: 'in development 3',
  };
};
