interface Props {
  className?: string;
  width?: string;
  height?: string;
  fill?: string;
}

function RightArrowModernIcon({ className, width, height, fill }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width || '7'}
      height={height || '7'}
      fill='none'
      viewBox='5 0 20 18'
      className={className || ''}
    >
      <path
        fill={fill || '#999'}
        d='M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z'
      ></path>
    </svg>
  );
}

export default RightArrowModernIcon;
