interface Props {
  className?: string;
  width?: string;
  height?: string;
  fill?: string;
}

function ArrowDownIcon({ className, width, height, fill }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width || '7'}
      height={height || '7'}
      fill='none'
      viewBox='0 0 16 16'
      className={className || ''}
    >
      <path
        fill={fill || '#999'}
        fillRule='evenodd'
        d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'
      />
    </svg>
  );
}

export default ArrowDownIcon;
