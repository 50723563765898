interface Props {
  className?: string;
}

function TextDocIcon({ className }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 26.601 32'
      className={className || ''}
    >
      <g id='Rectangle' transform='translate(0 0.97)' fill='none' stroke='black' stroke-width='0.4'>
        <rect width='26.601' height='31.03' rx='3.56' stroke='none' />
        <rect x='0.5' y='0.5' width='25.601' height='30.03' rx='3.06' fill='none' />
      </g>
      <g
        id='Rectangle-2'
        data-name='Rectangle'
        transform='translate(7.882)'
        fill='#fff'
        stroke='black'
        stroke-width='0.4'
      >
        <rect width='10.837' height='2.909' rx='1.455' stroke='none' />
        <rect x='0.5' y='0.5' width='9.837' height='1.909' rx='0.955' fill='none' />
      </g>
      <g
        id='Rectangle_Copy_14'
        data-name='Rectangle Copy 14'
        transform='translate(7.882 6.788) rotate(90)'
        fill='#fff'
        stroke='black'
        stroke-width='0.4'
      >
        <rect width='15.515' height='2.956' rx='1.478' stroke='none' />
        <rect x='0.5' y='0.5' width='14.515' height='1.956' rx='0.978' fill='none' />
      </g>
      <g
        id='Rectangle_Copy_19'
        data-name='Rectangle Copy 19'
        transform='translate(7.882 24.242) rotate(90)'
        fill='#fff'
        stroke='black'
        stroke-width='0.4'
      >
        <rect width='2.909' height='2.956' rx='1.455' stroke='none' />
        <rect x='0.5' y='0.5' width='1.909' height='1.956' rx='0.955' fill='none' />
      </g>
      <path
        id='Line_7'
        data-name='Line 7'
        d='M.343.439H9.817'
        transform='translate(11.858 6.833)'
        fill='none'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='0.4'
      />
      <path
        id='Line_7_Copy_2'
        data-name='Line 7 Copy 2'
        d='M.343.439H9.817'
        transform='translate(11.858 14.591)'
        fill='none'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='0.4'
      />
      <path
        id='Line_7_Copy_4'
        data-name='Line 7 Copy 4'
        d='M.343.439H9.817'
        transform='translate(11.858 22.348)'
        fill='none'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='0.4'
      />
      <path
        id='Line_7_Copy'
        data-name='Line 7 Copy'
        d='M.343.439H9.817'
        transform='translate(11.858 10.712)'
        fill='none'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='0.4'
      />
      <path
        id='Line_7_Copy_3'
        data-name='Line 7 Copy 3'
        d='M.343.439H9.817'
        transform='translate(11.858 18.47)'
        fill='none'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='0.4'
      />
      <path
        id='Line_7_Copy_5'
        data-name='Line 7 Copy 5'
        d='M.343.439H9.817'
        transform='translate(11.858 26.227)'
        fill='none'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='0.4'
      />
    </svg>
  );
}

export default TextDocIcon;
