import React, { MouseEventHandler } from 'react';

interface Props {
  className?: string;
  fill?: string;
  width?: string;
  height?: string;
  onClick?: MouseEventHandler<Element>;
}

function XIcon({ className, fill, width, height, onClick }: Props) {
  const clickSVG = () => {
    return
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width || '20'}
      height={height || '20'}
      fill='none'
      viewBox='0 0 18 15'
      className={className || ''}
      onClick={onClick || clickSVG}
    >
      <path
        fill={fill || '#e41e1a'}
        d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z'
      />
    </svg>
  );
}

export default XIcon;
