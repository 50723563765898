import moment from 'moment';
import { checkboxValues } from '../types/Form';

export const claimReasonParser = (reasons: string[], t: any) => {
  let claimsValue = '';

  Object.keys(checkboxValues(t)).forEach((item) => {
    const claimData = checkboxValues(t)[item];
    const claimCode = claimData.claimCode;
    const isCodeExisted = reasons.find((reasonItem) => reasonItem === claimCode);

    if (isCodeExisted) {
      claimsValue = claimsValue ? `${claimsValue}, ${claimData.label}` : claimData.label;
    }
  });

  return claimsValue;
};

export const getDaysRemaining = (date: any) => {
  var initialDate = moment(date, 'DD/MM/YYYY hh:mm');
  var todaysdate = moment();

  const diffDate = initialDate.diff(todaysdate, 'days') + 1;

  return diffDate;
};

export const toTimestamp = (strDate: string) => {
  var datum = Date.parse(strDate);
  return datum / 1000;
};
