import tw, { css } from 'twin.macro';

interface Props {
  children?: JSX.Element;
  className?: string;
}

const GrayBoxWrapper = ({ children, className }: Props) => {
  return (
    <div
      tw='border border-gray-300 rounded-md'
      className={className}
    >
      <div>{children}</div>
    </div>
  );
};

export default GrayBoxWrapper;
