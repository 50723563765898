import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import FilesList from './FilesList';
import DefaultView from './DefaultView';
import { File } from '../../../types/Form';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';

const MAX_FILES_COUNT = 5;

interface Props {
  value: File[];
  name: string;
  placeholder?: string;
  error?: string;
  onChange: (files: File[]) => void;
}

const FileUploaderField = ({ value, placeholder, onChange, error }: Props) => {
  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = [] as File[];

      // @ts-ignore
      acceptedFiles.forEach((file) => {
        const isImage = file.type.toLocaleLowerCase().indexOf('image') !== -1;
        if (isImage) {
          const imageUrl = URL.createObjectURL(file);
          newFiles.push({
            previewUrl: imageUrl,
            file,
          });
        } else {
          newFiles.push({
            previewUrl: null,
            file,
          });
        }
      });
      const allFiles: File[] = [...value, ...newFiles];

      // @ts-ignore
      onChange(allFiles);
    },
    [onChange, value]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: MAX_FILES_COUNT,
  });

  const isFilesExisted = value.length > 0;

  const handleRemoveFile = (deleteIndex: number) => {
    const updatedFiles = value.filter((_item, index) => index !== deleteIndex);
    onChange(updatedFiles);
  };

  const renderFilesView = () => {
    if (isFilesExisted) {
      return <FilesList onFileDelete={handleRemoveFile} files={value} />;
    }

    return null;
  };

  const renderError = () => {
    if (error || value.length > 5) {
      return <div tw='text-sm text-red-100'>{error || t('maximum_number_of_files_allowed_5')}</div>;
    }
  };

  return (
    <div css={[tw`flex flex-wrap justify-start`, isFilesExisted && tw`gap-5`]}>
      <div className='flex overflow-x-auto'>{renderFilesView()}</div>
      {value.length <= 4 && (
        <div
          {...getRootProps()}
          css={[
            tw`flex border-grey-light items-center w-32 cursor-pointer box-border h-32 relative border rounded-md`,
            error && tw`border-red-500`,
          ]}
        >
          <input {...getInputProps()} />
          <DefaultView />
        </div>
      )}

      <div className='ml-2'>{renderError()}</div>
    </div>
  );
};

export default FileUploaderField;
