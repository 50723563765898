import { useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useHistory, useParams } from 'react-router-dom';
import useQuery from '../../hooks/use-query-hook';
import { updateExternalClaim } from '../../services/actions';
import Button from '../Button/Button';
import InputField from '../form/InputField';
import LeftArrow from '../icons/LeftArrow';
import AlertPopup from '../layout/AlertPopup';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';

interface Props {
  iban: string;
  setIban: Function;
  bic: string;
  setBic: Function;
  bank: string;
  setBank: Function;
  name: string;
  setName: Function;
  errorMessageIBAN: String;
  errorMessageBIC: String;
  errorMessageBANK: String;
  errorMessageNAME: String;
  clearErrors: Function;
  setIsOpenMainModal: Function;
  refetchData: Function;
  isMobile?: boolean;
  reclamationNum: string;
}

const BankTransfer = ({
  iban,
  setIban,
  bic,
  setBic,
  bank,
  setBank,
  name,
  setName,
  errorMessageIBAN,
  errorMessageBIC,
  errorMessageBANK,
  errorMessageNAME,
  clearErrors,
}: Props) => {
  const { t } = useTranslation();

  const handleInput = (type: string, value: string) => {
    switch (type) {
      case 'IBAN': {
        setIban(value);
        break;
      }
      case 'BIC': {
        setBic(value);
        break;
      }
      case 'BANK': {
        setBank(value);
        break;
      }
      case 'NAME': {
        setName(value);
        break;
      }
      default: {
        console.log('Cannot find such a case!');
        break;
      }
    }

    clearErrors();
  };

  return (
    <div className='mt-3'>
      <div
        className={`flex flex-col justify-between gap-3 mobile:flex-wrap tablet:flex-wrap laptop:flex-nowrap pl-7`}
      >
        <div style={{ width: '100%' }}>
          <InputField
            name={'iban'}
            placeholder={'IBAN'}
            value={iban}
            error={''}
            onChange={(e) => handleInput('IBAN', e.currentTarget.value.toUpperCase())}
          />
          <p className='text-red-100'>{errorMessageIBAN}</p>
        </div>
        <div style={{ width: '100%' }}>
          <InputField
            name={'bank'}
            placeholder={t('bank')}
            value={bank}
            error={''}
            onChange={(e) => handleInput('BANK', e.currentTarget.value)}
          />
          <p className='text-red-100'>{errorMessageBANK}</p>
        </div>

        <div style={{ width: '100%' }}>
          <InputField
            name={'bic'}
            placeholder={'BIC'}
            value={bic}
            error={''}
            onChange={(e) => handleInput('BIC', e.currentTarget.value.toUpperCase())}
          />
          <p className='text-red-100'>{errorMessageBIC}</p>
        </div>
        <div style={{ width: '100%' }}>
          <InputField
            name={'name'}
            placeholder={t('first_and_last_name')}
            value={name}
            error={''}
            onChange={(e) => handleInput('NAME', e.currentTarget.value)}
          />
          <p className='text-red-100'>{errorMessageNAME}</p>
        </div>
      </div>
    </div>
  );
};

export default BankTransfer;
