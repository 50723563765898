import PdfIcon from '../icons/PdfIcon';
import tw from 'twin.macro';
import DownloadIcon from '../icons/DownloadIcon';
import OpenIcon from '../icons/OpenIcon';
import { isMobile, isTablet } from 'react-device-detect';
import { useEffect, useRef, useState } from 'react';
import { download } from '../../services/actions';
import useQuery from '../../hooks/use-query-hook';
import { BASE_URL } from '../../constants';
import { useTranslation } from 'react-i18next';

interface Props {
  customDocument: any;
  className?: string;
  claimNum: string;
}

const PdfComponent = ({ customDocument, className, claimNum }: Props) => {
  const dataLink: any = useRef();
  const { t } = useTranslation();
  const [data, setData] = useState('');

  const queryParameters = useQuery();

  useEffect(() => {
    const fetchData = async () => {
      if (customDocument) {
        const response = await download(queryParameters, customDocument);

        setData(`${response.data}`);
      }
    };

    fetchData();
  }, []);

  const downloadFile = () => {
    const encryptedCustomDocument = customDocument
      .replaceAll('http://', 'cusd1')
      .replaceAll('/', 'cusd2')
      .replaceAll('&', 'cusd3')
      .replaceAll('.php', 'cusd4')
      .replaceAll('?', 'cusd5');
    window.location.assign(
      `${BASE_URL}/download_file/${encryptedCustomDocument}/${localStorage.getItem('access_token')}`
    );
  };

  return (
    <div
      style={{ backgroundColor: 'rgb(239 246 255)' }}
      tw='p-3 flex items-center rounded-lg'
      className={className}
    >
      <PdfIcon />
      <div tw='ml-3'>
        <p tw='text-sm mb-2 break-words font-medium text-gray-700'>{claimNum}</p>
        <div tw='text-sm flex mt-2 gap-4'>
          {!isMobile && !isTablet && (
            <a href={data} download tw='text-blue-600 font-light font-body flex' ref={dataLink}>
              {t('download')}{' '}
              <DownloadIcon width='16' height='16' className='ml-2' fill='rgb(37 99 235)' />
            </a>
          )}
          {isMobile || isTablet ? (
            <div tw='text-blue-600 font-light font-body flex' onClick={downloadFile}>
              {t('open')} <OpenIcon width='16' height='16' className='ml-2' fill='rgb(37 99 235)' />
            </div>
          ) : (
            <a
              href={customDocument}
              target='_blank'
              rel='noreferrer'
              tw='mobile:(ml-0) tablet:(ml-0) laptop:(ml-2) text-blue-600 font-light font-body flex'
            >
              {t('open')} <OpenIcon width='16' height='16' className='ml-2' fill='rgb(37 99 235)' />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default PdfComponent;
