import { useHistory, generatePath } from 'react-router-dom';
import useQuery from './use-query-hook';
import queryString from 'query-string';
import { removeLastCharacterIfPreviousIsNumber } from '../utils/stringFormatters';

interface Parameters {
  [key: string]: string | number;
}

const SLASH_SYMBOL = '/';

interface Response {
  onRedirect: (path: string, parameters?: Parameters, query?: Parameters) => void;
  onReload: () => void;
  onReplace: (path: string, parameters?: Parameters, query?: Parameters) => void;
}

const useAuthRedirect = (): Response => {
  const history = useHistory();
  const { idUser, hashKey, hashKey2 } = useQuery();

  const onRedirect = (path: string, parameters?: Parameters, query: Parameters = {}) => {
    const pathname = generatePath(path, parameters || {});

    if (removeLastCharacterIfPreviousIsNumber(history.location.pathname) === pathname) return;

    const stringified = queryString.stringify({
      idUser,
      hashKey,
      hashKey2,
      ...query,
    });

    const queryUrlParameters = stringified ? `?${stringified}` : '';

    const hasSlashAtTheEnd = pathname[pathname.length - 1] === SLASH_SYMBOL;
    const pathnameUrl = `${pathname}${hasSlashAtTheEnd ? '' : SLASH_SYMBOL}`;

    history.push({
      pathname: pathnameUrl,
      search: queryUrlParameters,
    });
  };

  const onReload = () => {
    history.go(0);
  };

  const onReplace = (path: string, parameters?: Parameters, query: Parameters = {}) => {
    const pathname = generatePath(path, parameters || {});

    const stringified = queryString.stringify({
      idUser,
      hashKey,
      hashKey2,
      ...query,
    });

    const queryUrlParameters = stringified ? `?${stringified}` : '';

    const hasSlashAtTheEnd = pathname[pathname.length - 1] === SLASH_SYMBOL;
    const pathnameUrl = `${pathname}${hasSlashAtTheEnd ? '' : SLASH_SYMBOL}`;
    
    history.replace({
      pathname: pathnameUrl,
      search: queryUrlParameters,
    });
  };

  return {
    onRedirect,
    onReload,
    onReplace,
  };
};

export default useAuthRedirect;
