interface Props {
  className?: string;
  width?: string;
  height?: string;
  fill?: string;
  cover?: boolean;
}

function NeutralEmoji({ className, width, height, fill, cover }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width || '7'}
      height={height || '7'}
      fill='none'
      viewBox='0 0 16 16'
      className={className || ''}
    >
      {!cover && (
        <>
          <path
            fill={fill || '#999'}
            d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'
          />
          <path
            fill={fill || '#999'}
            d='M4 10.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5zm3-4C7 5.672 6.552 5 6 5s-1 .672-1 1.5S5.448 8 6 8s1-.672 1-1.5zm4 0c0-.828-.448-1.5-1-1.5s-1 .672-1 1.5S9.448 8 10 8s1-.672 1-1.5z'
          />
        </>
      )}
      {cover && (
        <path
          fill={fill || '#999'}
          d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM4.5 6h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm5 0h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm-5 4h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1z'
        />
      )}
    </svg>
  );
}

export default NeutralEmoji;
