const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';

export const setAuthKeys = (accessToken?: string, refreshToken?: string) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken || '');
  localStorage.setItem(REFRESH_TOKEN, refreshToken || '');
};

export const getAccessToken = (): string => {
  return localStorage.getItem(ACCESS_TOKEN) || '';
};

export const getAuthRefreshToken = (): string => {
  return localStorage.getItem(REFRESH_TOKEN) || '';
};
