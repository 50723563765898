import tw, { styled } from 'twin.macro';

interface Props {
  children: JSX.Element;
}

const ButtonWrapperElement = styled.div(() => [
  tw`mobile:(w-full bg-white flex flex-col justify-center) relative tablet:h-full`,
]);

const ButtonWrapper = ({ children }: Props) => {
  return (
    <div>
      <ButtonWrapperElement>{children}</ButtonWrapperElement>
    </div>
  );
};

export default ButtonWrapper;
