import { Route, Switch } from 'react-router-dom';
import routePaths from './route-paths';
import ReturnPage from './views/ReturnPage';
import ClaimsListPage from './views/ClaimsListPage';
import ViewClaimPage from './views/ViewClaimPage';
import ErrorInvalidHash from './views/ErrorInvalidHash';
import ErrorExpiredHash from './views/ErrorExpiredHash';
import ViewClaimByCommonIdPage from './views/ViewClaimPageByCommonId';
import DownloadFilePage from './views/DownloadFilePage';
import PayOnline from './views/PayOnline';

export default function Routes() {
  return (
    <Switch>
      <Route path={routePaths.root} exact>
        <ReturnPage />
      </Route>
      <Route path={routePaths.myClaims}>
        <ClaimsListPage />
      </Route>
      <Route path={routePaths.viewClaim}>
        <ViewClaimPage />
      </Route>
      <Route path={routePaths.viewClaimByCommonId}>
        <ViewClaimByCommonIdPage />
      </Route>
      <Route path={routePaths.payOnline}>
        <PayOnline />
      </Route>
      <Route path={routePaths.downloadFilePage}>
        <DownloadFilePage />
      </Route>
      <Route path={routePaths.errorInvalidHash}>
        <ErrorInvalidHash />
      </Route>
      <Route path={routePaths.errorExpiredHash}>
        <ErrorExpiredHash />
      </Route>
    </Switch>
  );
}
