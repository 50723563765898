// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en/translation.json';
import bgTranslation from './locales/bg/translation.json';
import roTranslation from './locales/ro/translation.json';
import grTranslation from './locales/gr/translation.json';

// Define the type for your translation resources
const resources = {
  en: { translation: enTranslation },
  bg: { translation: bgTranslation },
  ro: { translation: roTranslation },
  gr: { translation: grTranslation },
} as const;

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('lang') || 'bg', // default language
  fallbackLng: 'en', // fallback language when translation is missing
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
