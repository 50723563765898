import { useState, useRef, MutableRefObject } from 'react';

import useOnClickOutside from '../../../hooks/use-on-click-outside';

import DropdownItem from './DropdownItem';
import { Option } from '../../../constants';

import 'twin.macro';
import useQuery from '../../../hooks/use-query-hook';

interface Props {
  options: Option[];
  onClick: (value: string) => void;
  icon: JSX.Element;
}

const Dropdown = ({ options, onClick, icon }: Props) => {
  const queryParameters = useQuery();

  const [isOpen, setOpen] = useState(false);

  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  useOnClickOutside(ref, () => isOpen && setOpen(false));

  const toggleDropdown = () => {
    setOpen(!isOpen);
  };

  const handleChange = (value: string) => {
    setOpen(false);
    onClick(value);
  };

  const renderDropdownContent = () => {
    if (isOpen) {
      return (
        <div
          ref={ref}
          tw='mobile:(w-screen-center left-dropdown-x-mobile fixed top-16) 
          tablet:(absolute left-dropdown-x top-dropdown-y w-60 right-0)
          px-4 py-2 top-10 bg-white  flex flex-col items-center justify-center
          text-grey-500 border border-2 border-grey-800'
        >
          {options.map((item, index, arr) => {
            if (item && item.value === 'logout') {
              if (queryParameters.idUser && queryParameters.hashKey && queryParameters.hashKey2)
                return '';
            }
            return (
              <DropdownItem
                key={item.value}
                onClick={() => handleChange(item.value)}
                isLast={index + 1 === arr.length}
              >
                {item.label}
              </DropdownItem>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div tw='relative'>
      <button
        type='button'
        id='toggle-button'
        tw='flex items-center justify-center focus:outline-none'
        onClick={toggleDropdown}
      >
        {icon}
      </button>
      {renderDropdownContent()}
    </div>
  );
};

export default Dropdown;
