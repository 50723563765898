import { useEffect, useRef } from 'react';
import useAuthRedirect from '../../../hooks/use-auth-redirect-hook';
import useQuery from '../../../hooks/use-query-hook';
import routePaths from '../../../route-paths';
import PaginationWrapper from './PagintaionWrapper';

const INITIAL_INDEX = 0;
const PER_PAGE_LIMIT = 8;
const DEFAULT_LABEL = 'Page navigation';

interface Props {
  listData: Object[];
  limitPerPage?: number;
  label?: string;
  renderComponent: (props: any) => JSX.Element;
  activePage: number;
  totalResults: number;
  setActivePage: Function;
  refetchData: Function;
}

const Pagination = ({
  listData,
  activePage = INITIAL_INDEX,
  limitPerPage = PER_PAGE_LIMIT,
  label = DEFAULT_LABEL,
  totalResults,
  renderComponent,
  setActivePage,
  refetchData,
}: Props) => {
  const { onReplace } = useAuthRedirect();
  const queryParameters = useQuery();
  const listRef: any = useRef();

  useEffect(() => {
    onReplace(
      routePaths.myClaims,
      { ...queryParameters, page: activePage + 1 },
      { ...queryParameters, page: activePage + 1 }
    );
  }, [activePage]);

  const handlePaginationChange = (ActivePageNumber: number) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setTimeout(() => {
      refetchData((ActivePageNumber + 1).toString());
    }, 200)
  };

  const renderList = () => {
    return listData?.map((item, index) => (
      <div className='pt-5' key={index}>
        {renderComponent(item)}
      </div>
    ));
  };

  return (
    <div ref={listRef} className={`font-body`}>
      {renderList()}
      <PaginationWrapper
        totalResults={totalResults}
        resultsPerPage={limitPerPage}
        onChange={handlePaginationChange}
        label={label}
        setActivePageIndex={setActivePage}
        activePageIndex={+activePage}
      />
    </div>
  );
};

export default Pagination;
