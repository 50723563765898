import React from 'react';
import tw, { css } from 'twin.macro';

interface Props {
  checked: boolean;
  name: string;
  error?: string;
  label: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

const CheckboxField = ({ checked, error, name, label, onChange }: Props) => {
  return (
    <div>
      <label tw='mb-2 inline-flex items-center cursor-pointer relative' htmlFor={name}>
        <input
          tw='w-4 h-4 box-border appearance-none border-grey-light border rounded-md focus:ring-0 focus:ring-offset-0'
          type='checkbox'
          name={name}
          id={name}
          checked={checked}
          onChange={onChange}
        />
        <span tw='ml-2.5 font-light text-sm	font-body text-black-100'>{label}</span>
      </label>
      <div tw='mt-2 font-light text-sm text-red-500'>{error}</div>
    </div>
  );
};

export default CheckboxField;
