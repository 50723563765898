import tw, { css } from 'twin.macro';

interface Props {
  children?: JSX.Element;
  top?: string;
  zIndex?: number;
  className?: string;
}

const GrayBackgroundWrapper = ({ children, top, className, zIndex }: Props) => {
  return (
    <div
      style={{
        background: 'rgb(243 244 246)',
        position: 'absolute',
        left: '0',
        top: top || 'auto',
        width: '100%',
        height: top ? `calc(100% - ${top})` : '100%',
        zIndex: zIndex || -100,
      }}
      className={className}
    >
      <div>{children}</div>
    </div>
  );
};

export default GrayBackgroundWrapper;
