import FilePreview from './FilePreview';
import { File } from '../../../types/Form';
import tw from 'twin.macro';

interface Props {
  files: File[];
  onFileDelete?: (index: number) => void;
}

const FilesList = ({ files, onFileDelete }: Props) => {
  return (
    <div tw='w-full'>
      <div css={[tw`flex gap-5 flex-wrap`]}>
        {files.map((data, index, arr) => (
          <div key={index} className='w-32 h-32'>
            <FilePreview
              data={data}
              index={index}
              isLast={arr.length === index + 1}
              onFileDelete={onFileDelete}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilesList;
