import tw, { css } from 'twin.macro';
import EcoinsIcon from '../../icons/EcoinsIcon';

interface Props {
  isSelected: boolean;
  onClick: (index: number) => void;
  label: string;
  index: number;
  isFirst: boolean;
  showEcointsIcon?: boolean;
  disabled?: boolean;
}

const TabElement = ({
  isSelected,
  onClick,
  label,
  index,
  disabled,
  showEcointsIcon,
}: Props) => {
  return (
    <>
      <div
        css={[
          css`
            z-index: 1;
            height: 3rem;
            font-size: 1rem !important;
            padding: 10px;

            font-size: 0.875rem !important;
          `,
          tw`tablet:(w-43) flex cursor-pointer items-center justify-center text-base border-b-2 border-transparent bg-white font-light text-black-100`,
          isSelected && tw`z-10 text-black-100 font-bold border-gray-500`,
          disabled && tw`text-gray-400`
        ]}
        onClick={() => (!disabled ? onClick(index) : null)}
      >
        {showEcointsIcon && <EcoinsIcon />}
        {label}
      </div>
    </>
  );
};

export default TabElement;
