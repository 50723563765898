import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';
import SmileEmoji from '../icons/SmileEmoji';

import tw, { css } from 'twin.macro';
import NeutralEmoji from '../icons/NeutralEmoji';
import FrownEmoji from '../icons/FrownEmoji';
import TextareaField from '../form/TextareaField';
import Button from '../Button/Button';
import { createFeedback } from '../../services/actions';
import useQuery from '../../hooks/use-query-hook';
import { Feedback } from '../../types/Feedback';
import AlertPopup from '../layout/AlertPopup';
import WhiteBoxWrapper from '../layout/WhiteBoxWrapper';
import ButtonWrapper from '../Button/ButtonWrapper';
import { useTranslation } from 'react-i18next';

interface Props {
  selected?: string;
  refetchData: Function;
  number: string | number;
}

const giveOpinionModalStyle = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.486)',
    zIndex: 100024234232342342,
  },
  content: {
    border: 'none',
    borderRadius: '8px',
    top: '40%',
    left: '50%',
    minWidth: '320px',
    maxWidth: '360px',
    height: 'fit-content',
    transform: 'translate(-50%, -35%)',
  },
};

const modalStyle = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.486)',
    zIndex: 100024234232342342,
  },
  content: {
    border: 'none',
    borderRadius: '8px',
    top: '40%',
    left: '50%',
    minWidth: '320px',
    maxWidth: '500px',
    height: 'fit-content',
    transform: 'translate(-50%, -50%)',
  },
};

const frown = 'frown';
const neutral = 'neutral';
const smile = 'smile';

const GiveYourOpinionModal = ({ selected, refetchData, number }: Props) => {
  const { t } = useTranslation();

  const [isFailed, setIsFailed] = useState(false);

  const [hoverFrownEmoji, setHoverFrownEmoji] = useState(false);
  const [hoverNeutralEmoji, setHoverNeutralEmoji] = useState(false);
  const [hoverSmileEmoji, setHoverSmileEmoji] = useState(false);

  const [selectedEmoji, setSelectedEmoji] = useState('');

  const [currentEmoji, setCurrentEmoji] = useState('');

  const [opinionMessage, setOpinionMessage] = useState('');

  const [isOpenGiveOpinionModal, setIsOpenGiveOpinionModal] = useState(false);
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const queryParameters = useQuery();
  const params: any = useParams();

  const skipAuth = !!(
    queryParameters.idUser &&
    queryParameters.hashKey &&
    queryParameters.hashKey2
  );

  const skipAuthFullParameters = !!(
    queryParameters.idUser &&
    queryParameters.hashKey &&
    queryParameters.hashKey2 &&
    (queryParameters.skipNumbers ||
      queryParameters.awbNumbers ||
      queryParameters.moneyTransferNumbers) &&
    queryParameters.time
  );

  useEffect(() => {
    if (selected) {
      setIsOpenGiveOpinionModal(false);
      setSelectedEmoji(selected);
    } else {
      const timerToShowTheGiveOpinionModal = setTimeout(() => {
        setIsOpenGiveOpinionModal(true);
      }, 2500);

      return () => clearTimeout(timerToShowTheGiveOpinionModal);
    }
  }, [selected]);

  const handleSubmit = async (evaluation: string, message?: string) => {
    if (loading) return;

    setLoading(true);
    const data = {
      claimId: params.id,
      evaluation: evaluation,
      number,
    };

    if (evaluation === frown || evaluation === neutral) {
      Object.assign(data, { message });
    }

    try {
      createFeedback(data as Feedback, queryParameters, skipAuth || skipAuthFullParameters).then(
        (e) => {
          if (e && e[0] && e[0].data) {
            setIsOpenSuccessModal(true);
          } else {
            console.log('err');
            setIsFailed(true);
          }
        }
      );
    } catch (err) {
      console.log('err', err);
      setIsFailed(true);
    }
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    const { name, value } = e.target;

    switch (name) {
      case 'opinionMessage':
        return setOpinionMessage(value);
      default:
        return;
    }
  };

  const closeSuccessModal = () => {
    setIsOpenSuccessModal(false);
    setCurrentEmoji('');
    refetchData();
  };

  const closeGiveOpinionModal = () => {
    setIsOpenGiveOpinionModal(false);
  };

  return (
    <>
      {selected && (
        <WhiteBoxWrapper className='p-5'>
          <div>
            <span className='font-bold text-sm'>{t('how_would_you_rate_the_service')}</span>
            {selected &&
              (selected === frown ? (
                <div tw='flex items-center gap-6 w-full mt-5'>
                  <div tw='flex items-center justify-center flex-col'>
                    <FrownEmoji width='35' height='35' fill='rgba(21, 101, 192)' cover={true} />
                    <span className='text-blue-800 text-xs text-center mt-2'>
                      {t('i_am_not_satisfied')}
                    </span>
                  </div>
                </div>
              ) : selected === neutral ? (
                <div tw='flex items-center gap-6 w-full mt-5'>
                  <div tw='flex items-center justify-center flex-col'>
                    <NeutralEmoji width='35' height='35' fill='rgba(21, 101, 192)' cover={true} />
                    <span className='text-blue-800 text-xs text-center mt-2'>
                      {t('i_cant_judge')}
                    </span>
                  </div>
                </div>
              ) : selected === smile ? (
                <div tw='flex items-center gap-6 w-full mt-5'>
                  <div tw='flex items-center justify-center flex-col'>
                    <SmileEmoji width='35' height='35' fill='rgba(21, 101, 192)' cover={true} />
                    <span className='text-blue-800 text-xs text-center mt-2'>
                      {t('i_am_satisfied')}
                    </span>
                  </div>
                </div>
              ) : (
                ''
              ))}
          </div>
        </WhiteBoxWrapper>
      )}
      {!selected && (
        <Modal
          isOpen={isOpenGiveOpinionModal}
          onRequestClose={closeGiveOpinionModal}
          contentLabel='Give opinion feedback modal'
          style={giveOpinionModalStyle}
        >
          <span className='font-light text-xl text-gray-800'>
            {t('how_would_you_rate_the_service')}
          </span>
          <div tw='flex gap-4 w-full mt-5'>
            <div
              tw='flex items-center content-center justify-center flex-col cursor-pointer'
              onMouseEnter={() => setHoverFrownEmoji(true)}
              onMouseLeave={() => setHoverFrownEmoji(false)}
              onClick={() => {
                setCurrentEmoji(frown);
              }}
            >
              <FrownEmoji
                width='35'
                height='35'
                fill='rgba(35, 56, 118)'
                cover={hoverFrownEmoji || currentEmoji === frown || selectedEmoji === frown}
              />
              <span className='text-blue-900 text-xs text-center mt-2'>
                {' '}
                {t('i_am_not_satisfied')}
              </span>
            </div>
            <div
              tw='flex items-center justify-center flex-col cursor-pointer'
              onMouseEnter={() => setHoverNeutralEmoji(true)}
              onMouseLeave={() => setHoverNeutralEmoji(false)}
              onClick={() => {
                setCurrentEmoji(neutral);
              }}
            >
              <NeutralEmoji
                width='35'
                height='35'
                fill='rgba(35, 56, 118)'
                cover={hoverNeutralEmoji || currentEmoji === neutral || selectedEmoji === neutral}
              />
              <span className='text-blue-900 text-xs text-center mt-2'> {t('i_cant_judge')}</span>
            </div>
            <div
              tw='flex items-center justify-center flex-col cursor-pointer'
              onMouseEnter={() => setHoverSmileEmoji(true)}
              onMouseLeave={() => setHoverSmileEmoji(false)}
              onClick={() => {
                setLoading(true);
                setCurrentEmoji('');
                handleSubmit(smile);
              }}
            >
              <SmileEmoji
                width='35'
                height='35'
                fill='rgba(35, 56, 118)'
                cover={hoverSmileEmoji || selectedEmoji === smile}
              />
              <span className='text-blue-900 text-xs text-center mt-2'> {t('i_am_satisfied')}</span>
            </div>
          </div>
          {(currentEmoji === frown || currentEmoji === neutral) && (
            <div tw='flex flex-col gap-5'>
              <h1 className='mt-5 text-sm font-light leading-5 text-gray-700'>
                {t('describe_how_we_can_be_more_helpful_next_time')}
              </h1>
              <TextareaField
                name={'opinionMessage'}
                placeholder={t('add_a_comment_on_the_process_or_a_recommendation_to_our_team')}
                value={opinionMessage}
                large
                error={''}
                onChange={handleInputChange}
              />
              <ButtonWrapper>
                <Button
                  disabled={!opinionMessage || opinionMessage.length < 10}
                  type={'button'}
                  variant={'cool-auto-width'}
                  onClick={() => handleSubmit(currentEmoji, opinionMessage)}
                >
                  {t('send')}
                </Button>
              </ButtonWrapper>
            </div>
          )}
        </Modal>
      )}

      {isFailed && (
        <AlertPopup
          show={isFailed}
          text={t('failed_to_get_grade')}
          subText={t('due_to_technical_error_we_were_unable_to_receive_your_rating')}
          onClick={() => {
            setIsFailed(false);
          }}
        />
      )}
      <Modal
        isOpen={isOpenSuccessModal}
        onRequestClose={closeSuccessModal}
        contentLabel='Give opinion feedback modal'
        style={modalStyle}
      >
        <div className='p-2'>
          <div>
            <h1 className='font-light text-xl'>{t('thanks_for_the_feedback')}</h1>
            <p className='text-gray-800 text-sm font-light mt-5'>
              {currentEmoji !== frown && currentEmoji !== neutral
                ? t('we_are_glad_that_you_are_satisfied_with_the_service_of_our_team')
                : t('we_are_sorry_that_we_are_not_able_to_assist_well_enough')}
            </p>
          </div>
          <p
            className='text-sm mt-12 cursor-pointer text-blue-700 font-light'
            onClick={closeSuccessModal}
          >
            {t('close')}
          </p>
        </div>
      </Modal>
    </>
  );
};

export default GiveYourOpinionModal;
