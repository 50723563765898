import React from 'react';

interface Props {
  className?: string;
  fill?: string;
}

function LogoIcon({ className, fill }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='98'
      height='20'
      fill={fill || 'none'}
      viewBox='0 0 98 20'
      className={className || ''}
    >
      <path
        fill={fill || '#fff'}
        d='M95.659.2H82.517c-1.474 0-2.826 1.164-3.02 2.6-.195 1.436.843 2.6 2.317 2.6h3.696l-1.598 11.8c-.209 1.546.909 2.8 2.496 2.8 1.588 0 3.044-1.254 3.254-2.8L91.26 5.4h3.696c1.474 0 2.826-1.164 3.02-2.6.195-1.436-.843-2.6-2.317-2.6zM15.044.2H5.188C3.6.2 2.144 1.453 1.934 3L.025 17c-.209 1.546.909 2.8 2.496 2.8h9.856c1.474 0 2.827-1.165 3.021-2.6.194-1.436-.844-2.6-2.318-2.6H6.1l1.26-9.2h6.982c1.474 0 2.827-1.164 3.02-2.6.195-1.436-.843-2.6-2.317-2.6z'
      ></path>
      <path
        fill={fill || '#fff'}
        d='M11.49 13.1c1.758 0 3.183-1.388 3.183-3.1 0-1.712-1.425-3.1-3.183-3.1-1.757 0-3.182 1.388-3.182 3.1 0 1.712 1.425 3.1 3.182 3.1zM75.46 0c-1.587 0-3.044 1.254-3.253 2.8l-.595 4.4h-5.954l.594-4.4c.21-1.546-.908-2.8-2.496-2.8-1.587 0-3.044 1.254-3.253 2.8l-1.946 14.4c-.21 1.546.908 2.8 2.496 2.8 1.587 0 3.044-1.254 3.253-2.8l.649-4.8h5.954l-.648 4.8c-.21 1.546.908 2.8 2.496 2.8 1.587 0 3.044-1.254 3.253-2.8l1.946-14.4c.21-1.546-.908-2.8-2.496-2.8zM48.06 0C41.885 0 37.27 3.739 36.44 10c-.835 6.3 2.752 10 8.915 10 6.147 0 10.75-3.7 11.618-10 .863-6.262-2.738-10-8.915-10zm3.165 10c-.419 3.1-2.39 4.8-5.166 4.8-2.822 0-4.288-1.7-3.869-4.8.42-3.1 2.348-4.8 5.166-4.8 2.79 0 4.288 1.7 3.87 4.8z'
      ></path>
      <path
        fill={fill || '#fff'}
        d='M48.06 0C41.885 0 37.27 3.739 36.44 10c-.835 6.3 2.752 10 8.915 10 6.147 0 10.75-3.7 11.618-10 .863-6.262-2.738-10-8.915-10zm3.165 10c-.419 3.1-2.39 4.8-5.166 4.8-2.822 0-4.288-1.7-3.869-4.8.42-3.1 2.348-4.8 5.166-4.8 2.79 0 4.288 1.7 3.87 4.8zM34.21 0c-.805 0-1.576.33-2.162.857l-.042.037-.01.01-6.93 6.358.604-4.462c.209-1.546-.909-2.8-2.496-2.8-1.588 0-3.044 1.254-3.253 2.8l-1.947 14.4c-.209 1.546.909 2.8 2.496 2.8 1.588 0 3.044-1.254 3.254-2.8l.662-4.902 5.669 6.76.025.03.045.054c.444.506 1.113.82 1.9.82 1.56 0 2.99-1.231 3.196-2.75.104-.767-.125-1.46-.578-1.959l-4.88-5.473 5.75-5.036c.61-.502 1.043-1.21 1.149-1.994C36.867 1.231 35.77 0 34.21 0z'
      ></path>
    </svg>
  );
}

export default LogoIcon;
