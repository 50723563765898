interface Props {
  className?: string;
  width?: string;
  height?: string;
  fill?: string;
}

function HorizontalDotsIcon({ className, width, height, fill }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width || '16'}
      height={height || '16'}
      fill={fill || 'blue'}
      viewBox='0 0 16 16'
      className={className || ''}
    >
      <path d='M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z' />
    </svg>
  );
}

export default HorizontalDotsIcon;
