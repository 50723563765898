import ClipLoader from 'react-spinners/ClipLoader';

const override = `
  display: block;
  margin: 30% auto;

  @media (max-width: 768px) {
     margin: 100% auto;
  }
  `;

interface Props {
  size?: number;
}

const LoadingSpinner = ({ size = 150 }: Props) => {
  return (
    <div>
      <ClipLoader css={override} color={'rgb(21,66,130)'} loading={true} size={size} />
    </div>
  );
};

export default LoadingSpinner;
