import { Fragment } from 'react';
import tw from 'twin.macro';
import DoneIcon from '../icons/DoneIcon';

export interface StepperData {
  label?: string;
}

interface Props {
  stepperData: Array<StepperData>;
  active: number;
  className?: string;
}

const StepperComponent = ({ stepperData, active, className }: Props) => {
  const renderBar = () => {
    return (
      <div className={className || ''}>
        <div tw='flex justify-between items-center w-full'>
          {stepperData.map((step, index) => {
            const counter = index + 1;
            if (active === 3)
              return (
                <Fragment key={index}>
                  {counter < active && <div tw='p-3 rounded-full bg-green-100'></div>}
                  {counter === active && (
                    <div tw='p-1 rounded-full border-[0.2rem] border-green-100 flex items-center justify-center'>
                      <DoneIcon fill='rgb(82, 150, 43)' width='10' height='10' strokeWidth='2' />
                    </div>
                  )}
                  {counter > active && <div tw='p-2 rounded-full bg-green-100'></div>}
                  {counter < active && counter !== stepperData.length && (
                    <hr tw='w-full border-2 border-green-100' />
                  )}
                  {counter >= active && counter !== stepperData.length && (
                    <hr tw='w-full border border-green-100' />
                  )}
                </Fragment>
              );
            if (active === 1)
              return (
                <Fragment key={index}>
                  {counter < active && <div tw='p-1 rounded-full bg-green-100'></div>}
                  {counter === active && (
                    <div tw='p-1 rounded-full border-[0.2rem] border-black-100 flex items-center justify-center'>
                      <DoneIcon fill='black' width='10' height='10' strokeWidth='2' />
                    </div>
                  )}
                  {counter > active && <div tw='p-2 rounded-full bg-gray-400'></div>}
                  {counter < active && counter !== stepperData.length && (
                    <hr tw='w-full border-4 border-green-100' />
                  )}
                  {counter >= active && counter !== stepperData.length && (
                    <hr tw='w-full border border-gray-400' />
                  )}
                </Fragment>
              );
            return (
              <Fragment key={index}>
                {counter < active && <div tw='p-3 rounded-full bg-blue-500'></div>}
                {counter === active && (
                  <div tw='p-1 rounded-full border-[0.2rem] border-blue-500 flex items-center justify-center'>
                    <DoneIcon fill='rgb(59 130 246)' width='10' height='10' strokeWidth='2' />
                  </div>
                )}
                {counter > active && <div tw='p-2 rounded-full bg-gray-400'></div>}
                {counter < active && counter !== stepperData.length && (
                  <hr tw='w-full border-2 border-blue-500' />
                )}
                {counter >= active && counter !== stepperData.length && (
                  <hr tw='w-full border border-gray-400' />
                )}
              </Fragment>
            );
          })}
        </div>
        <div tw='flex justify-between'>
          {stepperData.map((step, index) => {
            const counter = index + 1;
            if (active === 3)
              return (
                <Fragment key={index}>
                  {counter === active && (
                    <span
                      key={index}
                      tw='text-[13px] font-normal text-green-100 font-bold w-full'
                      style={{ textAlign: 'end' }}
                    >
                      {step.label}
                    </span>
                  )}
                  {counter === 2 && (
                    <span
                      key={index}
                      tw='text-[13px] text-gray-500 w-full'
                      style={{ textAlign: 'center' }}
                    >
                      {step.label}
                    </span>
                  )}
                  {counter === 1 && (
                    <span
                      key={index}
                      tw='text-[13px] text-gray-500 w-full'
                      style={{ textAlign: 'start' }}
                    >
                      {step.label}
                    </span>
                  )}
                </Fragment>
              );
            if (active === 1)
              return (
                <Fragment key={index}>
                  {counter === active && (
                    <span
                      key={index}
                      tw='text-[13px] font-normal text-black-100 font-bold'
                      style={{ textAlign: 'start' }}
                    >
                      {step.label}
                    </span>
                  )}
                  {counter === 2 && (
                    <span
                      key={index}
                      tw='text-[13px] text-gray-500'
                      style={{ textAlign: 'center' }}
                    >
                      {step.label}
                    </span>
                  )}
                  {counter === 3 && (
                    <span
                      key={index}
                      tw='text-[13px] text-gray-500'
                      style={{ textAlign: 'end' }}
                    >
                      {step.label}
                    </span>
                  )}
                </Fragment>
              );
            return (
              <Fragment key={index}>
                {counter === active && (
                  <span
                    key={index}
                    tw='text-[13px] font-normal text-blue-500 font-bold'
                    style={{ textAlign: 'center' }}
                  >
                    {step.label}
                  </span>
                )}
                {counter === 1 && (
                  <span
                    key={index}
                    tw='text-[13px] text-gray-500'
                    style={{ textAlign: 'start' }}
                  >
                    {step.label}
                  </span>
                )}
                 {counter === 3 && (
                  <span
                    key={index}
                    tw='text-[13px] text-gray-500'
                    style={{ textAlign: 'end' }}
                  >
                    {step.label}
                  </span>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    );
  };
  return renderBar();
};

export default StepperComponent;
