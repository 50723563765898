import tw from 'twin.macro';
import OfficeLocatorMobile from './OfficeLocatorMobile';
import OfficeIconMobile from '../icons/OfficeMobile';
import { useTranslation } from 'react-i18next';

interface Props {
  showMap: boolean;
  setShowMap: Function;
  hoveredBox: string;
  setHoveredBox: Function;
  setIsOpenMainModal: Function;
  refetchData: Function;
  office: any;
  setOffice: Function;
  defaultOffice: any;
  isSelectedDefault: boolean;
  setIsSelectedDefault: Function;
}

const OfficePaymentMobile = ({
  showMap,
  setShowMap,
  office,
  setOffice,
  defaultOffice,
  isSelectedDefault,
  setIsSelectedDefault,
}: Props) => {
  const { t } = useTranslation();

  const handleInputChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    const { checked } = e.target;

    setIsSelectedDefault(checked);
  };

  return (
    <div>
      <div className='flex flex-col gap-5 w-50 mt-5 mobile:flex-wrap tablet:flex-wrap laptop:flex-nowrap justify-between'>
        {!showMap && !office?.id && (
          <div tw='ml-[1.6rem]'>
            <label
              tw='inline-flex items-center cursor-pointer relative'
              htmlFor={'recommended_office'}
            >
              <input
                tw='w-4 h-4 box-border appearance-none border-grey-light border rounded-md focus:ring-0 focus:ring-offset-0'
                type='checkbox'
                name={'recommended_office'}
                id={'recommended_office'}
                checked={isSelectedDefault}
                onChange={handleInputChange}
              />
              <span tw='flex flex-col ml-3'>
                <span tw='text-[13px] font-light text-black-100'>
                  {defaultOffice?.address?.fullAddress}
                </span>
                <span tw='text-[11px] font-light text-gray-500'>{t('often_chosen')}</span>
              </span>
            </label>
          </div>
        )}
        {!showMap && office?.id && (
          <div className={`w-full rounded-lg flex w-full`}>
            <div tw='flex justify-center items-center ml-[1.3rem]'>
              <OfficeIconMobile />
            </div>
            <div className='flex flex-col ml-2'>
              <span tw='text-[13px] font-semibold text-black-100'>
                <b>{office?.name}</b>
              </span>
              <span tw='text-[11px] text-black-100'>{office?.address?.fullAddress}</span>
            </div>
          </div>
        )}
        {!isSelectedDefault && (
          <OfficeLocatorMobile
            showMap={showMap}
            setShowMap={setShowMap}
            setOffice={setOffice}
            activatorText={office?.id ? t('choose_another_office') : t('select_on_the_map')}
          />
        )}
      </div>
    </div>
  );
};

export default OfficePaymentMobile;
