import React from 'react';
import RemoveIcon from '../../icons/RemoveIcon';

import 'twin.macro';

interface Props {
  onClick: () => void;
}

const RemoveButton = ({ onClick }: Props) => {
  return (
    <span
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onClick();
      }}
      tw='flex items-center justify-center h-6 w-6 bg-blue-400 rounded-full absolute top-0 right-0'
    >
      <RemoveIcon className='mt-0.5 -ml-0.5' />
    </span>
  );
};

export default RemoveButton;
