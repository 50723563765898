interface Props {
  className?: string;
  width?: string;
  height?: string;
  fill?: string;
  cover?: boolean;
}

function HrefArrow({ className, width, height, fill, cover }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width || '16'}
      height={height || '16'}
      fill={fill || 'blue'}
      className='bi bi-arrow-up-right'
      viewBox='0 0 16 16'
    >
      <path
        fill-rule='evenodd'
        d='M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z'
      />
    </svg>
  );
}

export default HrefArrow;
